<template>
  <v-container style="padding: 0px;">
    <slot />
    <div class="parent-container">
      <div
        v-for="(breakdown, breakdownIndex) in model.breakdowns"
        :key="breakdownIndex"
      >
        <span v-if="isSizeRequired && breakdown.color !== '' && (breakdown.error || hasEmptySize(breakdown.sizeAndQuantity))" style="padding-left:8px; color:red;">Select any size *</span>
        <div style="display: flex;">
          <div
            v-if="showBreakdowns"
            class="row-container"
          >
            <div class="cell-container">
              <div :id="`${rowId}-${breakdownIndex}`" class="content-cell">
                <div>
                  <v-text-field
                    v-model="breakdown.color"
                    @input="onColorChange(breakdown.color, breakdownIndex)"
                    hint="Please ensure you mention the color and pantone number"
                    maxlength="10"
                    :error-messages="breakdown.colorError ? 'Color is required' : ''"
                    required
                    outlined
                    dense
                    color="purple darken-3"
                    style="width: 150px;"
                    :readonly="!showEdit"
                  >
                    <template slot="label">
                    <span>{{`Color ${breakdownIndex + 1}`}}</span>
                    <span class="required-field">*</span>
                    </template>
                  </v-text-field>
                </div>
              </div>
              <div
                v-for="(size, index) in breakdown.sizeAndQuantity"
                :key="index"
                class="content-cell"
              >
                <div v-if="!dropdown">
                  <v-text-field
                    color="purple darken-3"
                    type="number"
                    :min="min"
                    :max="max"
                    v-model="size.quantity"
                    @input="updateBreakdowns(breakdown.color, breakdownIndex)"
                    outlined
                    dense
                    :readonly="!showEdit"
                    style="width: 150px;"
                  >
                    <template slot="label">
                      <span>{{size.size}}</span>
                    </template>
                  </v-text-field>
                </div>
                <div v-else>
                  <v-select
                    color="purple darken-3"
                    v-model="size.quantity"
                    :items="quantitiesList"
                    :label="size.size"
                    @input="updateBreakdowns(breakdown.color, breakdownIndex)"
                    outlined
                    dense
                    :disabled="!showEdit"
                    style="width: 80px;"
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="remove-button" v-if="showEdit">
              <div class="tooltip" style="min-height: 40px;">
                <v-btn
                  class="btn-hover"
                  @click="removeColor(breakdownIndex)"
                  fab
                  dark
                  x-small
                  color="error"
                >
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>
                <span class="tooltiptext">Remove color</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showBreakdowns && showEdit" class="tooltip mb-1 ml-3">
        <v-btn
          @click="addColor()"
          fab
          dark
          x-small
          color="purple darken-4"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
        <span class="tooltiptext">Add Color</span>
      </div>
    </div>

    <v-text-field
      :id="`${rowId}-total`"
      type="number"
      class="mt-6"
      v-model="model.total"
      outlined
      :readonly="true"
      :error-messages="totalError ? ['The quantity should be between 50 and 100,000,000'] : null"
      dense
      color="purple darken-3"
    >
      <template slot="label">
        <span>{{quantityTitle}}</span>
        <span class="required-field">*</span>
      </template>
    </v-text-field>
  </v-container>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "SizeBreakdown",
  props: {
    rowId: {
      type: String,
      default: ''
    },
    totalError: {
      type: Boolean,
      default: false
    },
    isSizeRequired: {
      type: Boolean,
      default: false
    },
    quantityTitle: {
      type: String,
      default: ''
    },
    total: {
      type: [Number, String],
      default: null
    },
    showBreakdowns: {
      type: Boolean,
      default: false
    },
    sizeChart: {
      type: Object,
      default: () => ({
          id: "",
          category: "",
          sizes: [],
          image: null,
      })
    },
    breakdowns: {
      type: Array,
      default: () => []
    },
    dropdown: {
      type: Boolean,
      default: false
    },
    min: {
      type: [Number, String],
      default: 0
    },
    max: {
      type: [Number, String],
      default: 5
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    showError: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    sizeChart(val) {
      this.model.breakdowns = [];
      this.model.total = this.calculateTotal();
    },
    breakdowns(values){
      this.model.breakdowns = values
      this.model.total = this.calculateTotal();
    }

  },
  computed: {
    quantitiesList(){
      return _.range(parseInt(this.min), parseInt(this.max) + 1);
    },
    getBreakdowns(){
      return this.model.breakdowns
    }
  },
  data: () => ({
    model: {
      breakdowns: [],
      total: 0
    }
  }),
  mounted() {
    this.model.breakdowns = this.breakdowns
    if(this.total){
      this.model.total = this.total
    } else {
      this.model.total = this.calculateTotal();
    }
    this.$emit('getTotal', this.model.total);
  },
  methods: {
    updateBreakdowns(color, index){
      this.model.total = this.calculateTotal();
      this.onColorChange(color, index);
      this.$emit('getTotal', this.model.total);
    },
    onColorChange(color, index){
      this.model.breakdowns[index] = {
        color: color,
        error: this.setError(index) || !color,
        colorError: !color ? true : false,
        sizeAndQuantity: this.setSizeAndQuantities(index)
      }
      if(!color) this.model.total = this.calculateTotal();
      this.$emit('update-breakdowns', this.model.breakdowns)
    },
    addColor() {
      this.model.breakdowns.push({
        color: '',
        error: this.setError(this.model.breakdowns.length),
        colorError: false,
        sizeAndQuantity: this.setSizeAndQuantities(this.model.breakdowns.length)
      })
      this.$emit('update-breakdowns', this.model.breakdowns)
    },
    removeColor(index) {
      this.model.breakdowns.splice(index, 1);
      this.$emit('update-breakdowns', this.model.breakdowns)
    },
    setSizeAndQuantities(index) {
      if (
        this.model.breakdowns[index] &&
        this.model.breakdowns[index].sizeAndQuantity &&
        this.model.breakdowns[index].sizeAndQuantity.length
      ) {
        return this.model.breakdowns[index].sizeAndQuantity;
      }

      const sizeAndQuntities = this.sizeChart.sizes.map(label => {
        return {quantity: parseInt(this.min), size: label};
      })
      return sizeAndQuntities;
    },
    setError(index, value) {
      if (arguments.length === 2) {
        this.model.breakdowns[index].error = value;
        return;
      }

      return this.model.breakdowns[index] && this.hasEmptySize(this.model.breakdowns[index].sizeAndQuantity);
    },

    hasEmptySize(sizeAndQuantity) {
      let total = 0;
      sizeAndQuantity.forEach(size => {
        total += !size.quantity ? 0 : parseInt(size.quantity);
      });

      if (total !== 0)
        return false;

      return true;
    },
    calculateTotal(){
      let total = 0;
      this.model.breakdowns.forEach(element => {
        element.sizeAndQuantity.forEach(size => {
          total += !size.quantity ? 0 : parseInt(size.quantity);
        })
      });
      return total;
    }
  }
}
</script>
<style lang="css" scoped>
.parent-container {
  border: 1px solid #582963;
  border-radius: 5px;
  padding: 10px 5px;
}

.row-container {
  width: 100%;
  display: flex;
  margin-top: 5px;
}

.cell-container {
  width: calc(100% - 38px);
  overflow-x: auto;
  display: flex;
}

.content-cell {
  padding: 3px 12px;
}

.remove-button {
  width: 32px;
  margin: 0px 3px;
  overflow: visible;
  padding: 7px 4px 4px 4px;
}

.tech-pack-production {
  height: 500px;
}

.required-field {
  color: red;
  font-size: 20px;
}

.contain {
  border: 1px solid #582963;
  height: 150px;
  overflow: hidden;
  resize: both;
  width: 220px;
  border-radius: 5px;
  cursor: pointer;
}

.dropzone:hover {
  border: dashed #c6a8ec;
}

.validationAlert {
  font-size: 12;
  color: #ff5252;
}

.dropzone {
  border-radius: 5px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #37474f;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100;
  width: 120px;
  top: 80%;
  left: 50%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.spinner {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 999;
}

.btn-align {
  display: flex;
  justify-content: flex-end;
}

.download-delete-btn {
  margin-right: 0;
}

.md-table-cell {
  width: 100px !important;
  border-top: none !important;
}

.tbody .md-table-row td {
  border-top: none !important;
}

.md-content {
  border: 1px solid #582963 !important;
  padding: 20px 20px 0px 20px !important;
  border-radius: 5px !important;
}

.md-table-cell >>> .md-table-cell-container {
  padding: 3px 0px 0px 8px !important;
}

.md-table.md-theme-default
.md-table-row:hover:not(.md-header-row)
.md-table-cell {
  background: none !important;
}

.section {
  padding: 20px 50px 50px 50px;
}

@media only screen and (max-width: 600px) {
  .btn-align {
    display: grid;
    justify-content: center;
    grid-gap: 10px;
  }

  .section {
    padding: 20px 10px 10px 10px;
  }
}

</style>
