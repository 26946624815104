<template>
    <v-card>
      <v-card-title>
        <p class="display-2 black--text underline text-left">
          Provide the reason for updating
        </p>
      </v-card-title>
      <v-divider/>
      <v-card-text height="1000px" class="pt-0">
        <v-container class="py-0">
          <v-form ref="addComment" class="mt-5">
            <v-row>
              <v-col cols="12" md="12">
                <p
                  class="text-left mb-1 ml-0 formLabel font-weight-light grey--text text--darken-1 "
                >
                  Add your comment here
                  <span class="required-field">*</span>
                </p>
                <v-textarea
                  v-model="model.newComment"
                  outlined
                  dense
                  color="purple darken-3"
                  maxLength="5000"
                  :rules="rules.commentRules"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <md-button
          class="md-raised md-primary"
          style="background-color: #582963; color: #EEF0FB;"
          @click="userCommentClose"
        >Close
        </md-button>
        <md-button
          class="md-raised md-primary"
          style="background-color: #582963; color: #EEF0FB;"
          @click="saveComment"
        >Submit
        </md-button>
      </v-card-actions>
    </v-card>
</template>

<script>
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
import validationMixins from "../../validation/validation_mixin";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import { resetLeaveAlert, setLeaveAlert } from '../../utils/confirmBox';

export default {
  name: "Comment",
  mixins: [validationMixin,validationMixins],
  components: {},

  data() {
    return {
      userComment: false,
      rules: {
        commentRules: [
          (value) => value && value.length > 1000 ? "Must be no more than 1000 characters" : !!value || "Comment is required",
        ],
      },
      model: {
        newComment: null,
      },
    };
  },
  props: {
    isOwner: {
      type: Boolean,
    },
    page:{
      type:Number,
      required: true
    },
    serviceType: {
      type:String,
      required: true
    },
  },
  methods: {
    userCommentClose() {
      this.userComment = false;
      this.$refs.addComment.resetValidation();
      this.model.newComment = null;
      this.$emit('comment-close');
    },
    saveComment() {
      this.$refs.addComment.validate();
      if (!this.model.newComment) {
        return
      }
      const payLoad = {
        inquiry_id: this.inquiryId,
        comment: this.model.newComment,
        service_type: this.serviceType,
        page: this.page,
      };

      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/add_service_comment`, payLoad
        )
        .then((response) => {
          this.$emit("comment-submit", 1);
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong");
          }
        });

      this.userComment = false;
      this.model.newComment = null;
      this.$refs.addComment.resetValidation();
    },



    getTechPack() {
      return restAdapter.get(`/api/inquiries/${this.inquiryId}/tech_pack`);
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    getCategories() {
      return restAdapter.get("/api/inquire_categories");
    },
    getCategoryName(id) {
      const category = this.categories.find((category) => category.id == id);
      this.model.category = category.id;
    },
    submit() {
      this.$v.model.$touch();

      if (this.$v.model.$invalid) {
        return;
      }
      const data = {
        style_name: this.model.styleName,
        category: this.model.category,
        other_category_details: this.model.otherCategory,
      };

      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/tech_pack`, data)
        .then((response) => {
          this.reset();
          notification.success("Tech Pack Details Saved Successfully");
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
      resetLeaveAlert();
      this.ignoreFormEdit = true;
      this.$v.model.$reset();
    },
  },

  computed: {
    styleNameErrors() {
      const errors = [];
      if (!this.$v.model.styleName.$dirty) return errors;
      !this.$v.model.styleName.required &&
      errors.push("Style name is required");
      return errors;
    },
    categoryValidationError() {
      if (!this.$v.model.category.required) {
        return "The category is required";
      }
      return null;
    },
  },

  mounted() {
    this.inquiryId = this.$route.params.id;

    this.loading = true;

    this.getCategories().then((response) => {
      this.categories = response.data.categories;
    }).then(() => {
      this.getInquiry(this.inquiryId).then((response) => {
        const inquiry = response.data.inquiry;
        this.model.styleNo = inquiry.style_number;
        this.model.styleName = inquiry.style_name;
        this.model.brandName = inquiry.customer.brand_name;
        this.model.designerFirstName = inquiry.customer.first_name;
        this.model.designerLastName = inquiry.customer.last_name;
        this.createdBy = inquiry.created_by;
        this.getCategoryName(parseInt(inquiry.categories));
        this.ignoreFormEdit = true;
      });
    });

    this.getTechPack().then((response) => {
      const techPack = response.data.techPack;
      if (techPack) {
        this.model.otherCategory = techPack.other_category_details;
        this.ignoreFormEdit = true;
      }
    });
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.tech-pack {
  height: 500px;
}

.tech-pack-align {
  margin-top: 10px 30px 30px 30px;
}

.btn-align {
  display: flex;
  justify-content: flex-end;
}

.section {
  padding: 20px 50px 50px 50px;
}

@media only screen and (max-width: 600px) {
  .btn-align {
    display: flex;
    justify-content: center;
  }

  .section {
    padding: 20px 10px 10px 10px;
  }
}
.list_color.md-selected::v-deep .md-list-item-text{
    color: #fa735f !important;
}
.color_stroke::v-deep .md-progress-spinner-circle{
    stroke: #492a67 !important;
}
.continue_button_color{
  background-color: #492a67 !important;
  border: #492a67 !important;
}
.md_field_margin{
  margin-top: -15px;
}
.error_msg_color{
  color : #ff5252 !important;
}
</style>
