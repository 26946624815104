<template>
  <div v-if="loading" class="text-center tech-pack-fabric">
    <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
  </div>
  <div v-else style="margin-top: 10px 30px 30px 30px;">
    <div class="grey lighten-5 section" style="text-align: left;">
      <div id="fabric-progress-indicator">
        <div v-if="showProgress" class="file-uploading-indicator text-center">
          <label>Uploading {{progress}}%</label>
          <md-progress-bar class="color_stroke" md-mode="determinate" :md-value="progress"></md-progress-bar>
        </div>
      </div>
      <h2 class="mb-2">Fabric Details</h2>
      <v-divider></v-divider>
      <v-form :readonly="!isEditAccess" class="mt-6" @submit.prevent="submit">
        <v-select
          :items="items"
          color="purple darken-3"
          :error-messages="fabricSourcingErrors"
          v-model="model.fabricSourcing"
          outlined
          dense
        >
          <template slot="label">
            <span>Fabric Sourcing for the Mentioned Style</span>
            <span class="required-field">*</span>
          </template>
        </v-select>
        <v-textarea
          class="mt-4 mb-5"
          outlined
          name="input-7-4"
          v-model="model.fabricComposition"
          :error-messages="fabricCompositionErrors"
          @input="$v.model.fabricComposition.$touch()"
          @blur="$v.model.fabricComposition.$touch()"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          hint="Any other additional information about the fabric eg: 100% cotton"
        >
          <template slot="label">
            <span>Fabric Composition</span>
            <span class="required-field">*</span>
          </template>
        </v-textarea>
        <div class="mb-3">Fabric Image</div>
        <div v-if="fabricImagesTotalFileSizeError" style="color:red">{{fileSummationErrorMessage}}</div>
        <div v-if="fabricImagesFileSizeError" style="color:red">{{maxFileSizeErrorMessage}}</div>
        <vue-dropzone
          v-if="isEditAccess"
          class="dropzone mb-5"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="addFabricImages"
          @vdropzone-removed-file="removeFabricImages"
          @vdropzone-error="collectErrors"
        ></vue-dropzone>

        <div v-if="!isEditAccess && !model.filesFabric.length" style="color: red">* No any file has been uploaded !</div>

        <v-row class="mb-10" dense>
          <v-col
            v-for="(file, index) in model.filesFabric"
            :key="index"
            md="6"
            lg="3"
            sm="3"
            xs="6"
          >
            <v-card>
              <v-img
                v-if="
                  file.file.upload_name.split('.')[1] !== 'jpg' &&
                    file.file.upload_name.split('.')[1] !== 'JPG' &&
                    file.file.upload_name.split('.')[1] !== 'png' &&
                    file.file.upload_name.split('.')[1] !== 'jpeg'
                "
                :src="require('../../../assets/folder.png')"
                class="white--text align-end"
                height="150px"
              >
              </v-img>
              <v-img
                v-else
                :src="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                "
                class="white--text align-end"
                height="150px"
              >
              </v-img>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div v-text="file.file.title.substr(0, 20)"></div>
                </v-row
                >
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn v-if="isEditAccess" fab x-small @click="deleteFile(file.file.upload_name)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-textarea
          class="mt-4 mb-5"
          outlined
          name="input-7-4"
          label="Links"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          hint="Links to any supporting material"
          v-model="model.links"
        ></v-textarea>

        <div style="display: flex; justify-content: center;">
          <v-alert
            class="mt-5"
            type="error"
            max-width="300px"
            dense
            :value="alert"
            transition="scale-transition"
          >
            Remove unsupported files
          </v-alert>
        </div>
        <div class="btn-align">
          <v-btn color="primary" v-if="isEditAccess" @click="submit">Save</v-btn>

          <v-btn
            class="continue_button_color darken-3"
            @click="isEditAccess ? continueStep() : continueToNext()"
          >Continue
          </v-btn
          >
        </div>
      </v-form>
    </div>
    <v-dialog
      max-width="500"
      v-model="commentDialog"
    >
      <Comment
        @comment-submit="commentSubmit"
        @comment-close="commentDialog = false"
        :page="3"
        serviceType="tech_pack"
      />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
import * as conf from "../../../conf.yml";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import restAdapter from "../../../restAdapter";
import vue2Dropzone from "vue2-dropzone";
import notification from "../../../notification";
import utils from "../../../utils";
import Comment from "../Comment.vue";
import { resetLeaveAlert, setLeaveAlert } from '../../../utils/confirmBox';

export default {
  name: "Fabric",
  mixins: [validationMixin],
  components: {
    vueDropzone: vue2Dropzone,
    Comment
  },

  data() {
    return {
      baseUrl: "",
      unsupportedFiles: [],
      items: [
        "I Will be Supplying the Fabric",
        "Use Seamless Source Sourcing Service",
      ],
      loading: false,
      alert: false,
      fabricImagesTotalFileSizeError:false,
      fabricImagesFileSizeError:false,
      totalFileSize:102000000,
      maximumFileSize: 52000000,
      fileSummationErrorMessage:"Selected files are exceeding the maximum file summation limit (100mb)",
      maxFileSizeErrorMessage:"One or more files are too large to upload (50mb)",
      commentDialog: false,
      isSubmit: false,
      isContinue: false,
      dropzoneOptions: {
        url: "https://",
        autoProcessQueue: false,
        thumbnailWidth: 200, // px
        thumbnailHeight: 200,
        maxFilesize: 50,
        dictDefaultMessage:
          "<div style='filter: grayscale(1)'>📤</div> Drag and Drop (or) Choose File(s)",

        acceptedFiles: utils.acceptedFileTypes,
        addRemoveLinks: true,
      },

      model: {
        fabricImages: [],
        fabricSourcing: "",
        fabricComposition: "",
        links: "",
        filesFabric: [],
      },
      ignoreFormEdit: false,
      showProgress: false,
      progress: 0,
    };
  },

  validations: {
    model: {
      fabricSourcing: {required},
      fabricComposition: {required},
    },
  },

  props: {
    isOwner: {
      type: Boolean,
    },
    isEditAccess: {
      type: Boolean,
    },
  },

  watch: {
    model: {
      handler: function () {
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    }
  },

  methods: {
    continueToNext() {
      this.$emit("set-done");
    },
    continueStep() {
      this.$v.model.$touch();

      if(
        this.fabricImagesTotalFileSizeError ||
        this.fabricImagesFileSizeError
      ){
        return;
      }

      this.$nextTick(() => {
        if (document.querySelector(".v-messages__message")) {
          const domRect = document
            .querySelector(".v-messages__message")
            .getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      });

      if (this.$v.model.$invalid) {
        this.$emit("error");
        return;
      }
      this.isContinue = true;
      this.submit();
    },
    reset() {
      this.$emit("reset-status", false);
    },
    deleteFile(uniqueId) {
      restAdapter.delete("/api/tech_pack/file_delete/" + uniqueId).then(() => {
        notification.success("File deleted successfully");

        for (let index = 0; index < this.model.filesFabric.length; index++) {
          if (this.model.filesFabric[index].file.upload_name == uniqueId) {
            this.model.filesFabric.splice(index, 1);
          }
        }
      });
    },
    collectErrors(file, message, xhr) {
      this.unsupportedFiles.push({file});
    },
    addFabricImages: function (file) {

      if(file.size > this.maximumFileSize){
        this.fabricImagesFileSizeError = true;
      }

      this.model.fabricImages.push({file});

      let fileSize = 0;

      for (let index = 0; index < this.model.fabricImages.length; index++) {
        fileSize = fileSize + this.model.fabricImages[index].file.size;
      }

      this.fabricImagesTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
    },
    removeFabricImages(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.fabricImages.length; index++) {
        if (this.model.fabricImages[index].file.name === file.name) {
          this.model.fabricImages.splice(index, 1);
        }
        fileSize = fileSize + (this.model.fabricImages[index] ? this.model.fabricImages[index].file.size : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.fabricImagesTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
      this.fabricImagesFileSizeError = this.unsupportedFiles.length > 0 ? true : false;
    },
    getFabric() {
      return restAdapter.get(
        `/api/inquiries/${this.inquiryId}/tech_pack/fabric`
      );
    },
    commentSubmit() {
      this.commentDialog = false;
      this.isSubmit = true;
      this.submit();
      this.continueNextPage();
    },
    continueNextPage() {
      if(this.isContinue){
        this.isContinue = false;
        this.$emit('set-done');
      }
    },
    addComment() {
      if (!this.isOwner) {
        this.commentDialog = true;
      } else {
        this.commentSubmit();
      }
    },
    submit() {
      this.$v.model.$touch();

      if(
        this.fabricImagesTotalFileSizeError ||
        this.fabricImagesFileSizeError
      ){
        return;
      }

      this.$nextTick(() => {
        if (document.querySelector(".v-messages__message")) {
          const domRect = document
            .querySelector(".v-messages__message")
            .getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      });

      if (this.$v.model.$invalid) {
        return;
      }

      if (this.unsupportedFiles.length > 0) {
        this.alert = true;
        return;
      }
      if(!this.isSubmit && this.$store.state.routeLeaveAlert){
        this.addComment();
        return;
      }
      this.isSubmit = false;
      const formData = new FormData();

      for (let index = 0; index < this.model.fabricImages.length; index++) {
        formData.append("fabric_files[]", this.model.fabricImages[index].file);
      }
      formData.append("fabric_sourcing", this.model.fabricSourcing);
      formData.append("fabric_composition", this.model.fabricComposition);
      formData.append("links", this.model.links);

      this.showProgress = true;
      document.getElementById('fabric-progress-indicator').scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });

      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/tech_pack/fabric`, formData,
        {
          onUploadProgress: progressEvent => {
            this.progress = (Math.floor((progressEvent.loaded * 100) / progressEvent.total))
          }
        }
        )
        .then((response) => {
          this.showProgress = false;
          this.reset();
          notification.success("Fabric Details Saved Successfully");
        })
        .catch((error) => {
           this.showProgress = false;
          this.loading = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
      resetLeaveAlert();
      this.ignoreFormEdit = true;
      this.$v.model.$reset();
      this.continueNextPage();
    },
  },

  computed: {
    fabricSourcingErrors() {
      const errors = [];
      if (!this.$v.model.fabricSourcing.$dirty) return errors;
      !this.$v.model.fabricSourcing.required &&
      errors.push("Select the required option");
      return errors;
    },
    fabricCompositionErrors() {
      const errors = [];
      if (!this.$v.model.fabricComposition.$dirty) return errors;
      !this.$v.model.fabricComposition.required &&
      errors.push("Fabric composition is required");
      return errors;
    },
  },

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;

    this.loading = true;

    this.getFabric().then((response) => {
      const fabric = response.data.fabric;
      const inquiryProduction = response.data.inquiryProduction;
      if (fabric) {
        this.model.fabricSourcing = fabric.fabric_sourcing;
        this.model.links = fabric.links;

        for (let index = 0; index < response.data.fabric_files.length; index++) {
          this.model.filesFabric.push({
            file: response.data.fabric_files[index],
          });
        }
      }
      if (inquiryProduction) {
        this.model.fabricComposition = inquiryProduction.fabric_composition;
      }
      this.loading = false;
      this.ignoreFormEdit = true;
    });

    this.loading = false;
  },
};
</script>

<style lang="css" scoped>
.tech-pack-fabric {
  height: 500px;
}

.required-field {
  color: red;
  font-size: 20px;
}

.btn-align {
  display: flex;
  justify-content: flex-end;
}

.dropzone:hover {
  border: dashed #c6a8ec;
}

.dropzone {
  border-radius: 5px;
}

.dropzone >>> .dz-preview .dz-progress {
  opacity: 0 !important;
}

.section {
  padding: 20px 50px 50px 50px;
}

.dropzone >>> .dz-error-message {
  display: none !important;
}

.dropzone >>> .dz-preview .dz-details{
  background-color: rgb(198, 168, 236, .7) !important;
}

.dropzone >>> .dz-preview .dz-remove{
  color: #582963 !important;
}


@media only screen and (max-width: 600px) {
  .btn-align {
    display: flex;
    justify-content: center;
  }

  .section {
    padding: 20px 10px 10px 10px;
  }
}

.continue_button_color{
  background-color: #492a67 !important;
  border: #492a67 !important;
}

.md-progress-bar {
  height: 25px;
  margin: 5px;
}

.color_stroke::v-deep .md-progress-spinner-circle{
  stroke: #492a67 !important;
}

.md-progress-bar.md-theme-default.md-determinate::v-deep .md-progress-bar-fill{
  background-color: #492a67 !important;
}

.md-progress-bar.md-theme-default.md-determinate{
  background-color: #c6a8ec !important;
}

</style>
