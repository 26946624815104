<template>
  <div v-if="loading" class="text-center tech-pack">
    <md-progress-spinner
      class="color_stroke"
      md-mode="indeterminate"
    ></md-progress-spinner>
  </div>
  <v-form :readonly="!isEditAccess" v-else style="text-align: left;">
    <div class="grey lighten-5 section">
      <div id="progress-indicator">
        <div v-if="showProgress" class="file-uploading-indicator text-center">
          <label>Uploading {{ progress }}%</label>
          <md-progress-bar
            class="color_stroke"
            md-mode="determinate"
            :md-value="progress"
          ></md-progress-bar>
        </div>
      </div>
      <div style="display: flex;">
        <h2 class="mb-2">Style Size Detail</h2>
        <v-icon
          style="margin-left: auto; margin-right: 0;"
          class="mr-4 mb-4"
          color="blue-grey lighten-1"
          @click="scrollToBottom"
          dark
        >
          mdi-arrow-down-bold
        </v-icon>
      </div>
      <v-divider></v-divider>
      <div class="text-left">
        <span>Size Category</span>
        <span class="required-field">*</span>
      </div>

      <v-radio-group
        v-model="model.selectedSizeCategory"
        :error-messages="sizeCategoryErrors"
        @input="$v.model.selectedSizeCategory.$touch()"
        @blur="$v.model.selectedSizeCategory.$touch()"
      >
        <v-radio
          v-for="(category, index) in sizeCategory"
          :key="index"
          :label="category.category"
          :value="category.id"
          color="purple darken-3"
        ></v-radio>
      </v-radio-group>

      <v-text-field
        v-if="model.selectedSizeCategory === 4"
        label="Other"
        style="width: 300px;"
        v-model="model.otherCategory"
        maxlength="10"
        :error-messages="otherCategoryErrors"
        required
        @input="$v.model.otherCategory.$touch()"
        @blur="$v.model.otherCategory.$touch()"
        outlined
        dense
        color="purple darken-3"
      >
        <template v-if="!model.otherCategory" v-slot:append-outer>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                class="ml-1"
                v-on="on"
                :style="model.otherCategory ? 'color:gray;' : 'color:red;'"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            Please add other category type manually.
          </v-tooltip>
        </template></v-text-field
      >

      <div v-if="model.selectedSizeCategory !== null" class="mb-4">
        {{
          model.selectedSizeCategory === 1
            ? "Women's Geographical Size"
            : model.selectedSizeCategory === 2
            ? "Men's Geographical Size"
            : model.selectedSizeCategory === 3
            ? "Kid's Geographical Size"
            : "Sizes"
        }}
        <span class="required-field">*</span>
        <span v-if="model.selectedSizeCategory === 4">
          <template>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="ml-1"
                  v-on="on"
                  :style="model.otherCategory ? 'color:orange;' : 'color:red;'"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span v-if="model.otherCategory">
                Please add the sizes manually if the other category is selected
                .
              </span>
              <span v-else>
                This will appear after filling the other category .
              </span>
            </v-tooltip>
          </template>
        </span>
      </div>
      <!--men, women size chart radio group -->
      <v-radio-group
        v-if="
          model.selectedSizeCategory === 1 || model.selectedSizeCategory === 2
        "
        v-model="model.sizeChart"
        :error-messages="sizeChartErrors"
        @input="$v.model.sizeChart.$touch()"
        @blur="$v.model.sizeChart.$touch()"
      >
        <v-radio
          v-for="(chart, index) in model.selectedSizeCategory === 1
            ? mensSizeChart
            : model.selectedSizeCategory === 2
            ? womensSizeChart
            : null"
          :key="index"
          :label="chart.category"
          :value="chart.id"
          color="purple darken-3"
        ></v-radio>
      </v-radio-group>
      <div
        v-if="model.selectedSizeCategory === 4 && model.otherCategory !== ''"
      >
        <div
          v-for="(size, index) in model.otherSizes"
          :key="index"
          :set="(v = $v.model.otherSizes.$each[index])"
          style="display: flex;"
        >
          <v-text-field
            :label="`SIZE${index + 1}`"
            v-model="size.size"
            maxlength="10"
            @input="addOtherSizesSampleBulk(size.size, index)"
            :error-messages="v.size.$error ? ['Size is required'] : null"
            required
            outlined
            dense
            color="purple darken-3"
          ></v-text-field>
          <div class="tooltip ml-2">
            <v-btn
              class="mr-4"
              @click="removeSize(index)"
              fab
              dark
              x-small
              color="error"
            >
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn>
            <span class="tooltiptext">Remove size</span>
          </div>
        </div>
        <div class="tooltip mb-5">
          <v-btn
            class="mr-4 mb-4"
            @click="addSize"
            fab
            dark
            x-small
            color="purple darken-3"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <span class="tooltiptext">Add Size</span>
        </div>
      </div>

      <!-- kids category us/uk radio group -->
      <v-radio-group
        v-if="model.selectedSizeCategory === 3"
        v-model="model.kidsCategory"
        :error-messages="kidsCategoryErrors"
        @input="$v.model.kidsCategory.$touch()"
        @blur="$v.model.kidsCategory.$touch()"
      >
        <v-radio
          v-for="(chart, index) in kidsSizeChart"
          :key="index"
          :label="chart.category"
          :value="chart.id"
          color="purple darken-3"
        ></v-radio>
      </v-radio-group>

      <div
        v-if="model.kidsCategory !== null && model.selectedSizeCategory === 3"
      >
        <div v-for="(chart, index) in kidsSizeChart" :key="index">
          {{ model.kidsCategory === chart.id ? `Kids ${chart.category}` : "" }}
        </div>
      </div>
      <!-- kids size chart radio group -->
      <v-radio-group
        v-if="model.selectedSizeCategory === 3 && model.kidsCategory !== null"
        v-model="model.sizeChart"
        :error-messages="sizeChartErrors"
        @input="$v.model.sizeChart.$touch()"
        @blur="$v.model.sizeChart.$touch()"
      >
        <v-radio
          v-for="(chart, index) in model.selectedSizeCategory === 3 &&
          model.kidsCategory === 1
            ? kidsUkSizeCategory
            : model.selectedSizeCategory === 3 && model.kidsCategory === 2
            ? kidsUsSizeCategory
            : null"
          :key="index"
          :label="chart.category"
          :value="chart.id"
          color="purple darken-3"
        ></v-radio>
      </v-radio-group>

      <div
        v-if="model.selectedSizeCategory !== null && model.sizeChart !== null"
        style="display: flex; flex-direction: row;"
        class="mb-4"
      >
        <div class="pr-1">
          {{
            model.selectedSizeCategory === 1
              ? "Women's"
              : model.selectedSizeCategory === 2
              ? "Men's"
              : model.selectedSizeCategory === 3 && model.kidsCategory !== null
              ? ""
              : ""
          }}
        </div>
        <div
          v-for="(sizeChartItem, index) in model.selectedSizeCategory === 1
            ? womensSizeChart
            : model.selectedSizeCategory === 2
            ? mensSizeChart
            : model.selectedSizeCategory === 3 && model.kidsCategory == 1
            ? kidsUkSizeCategory
            : model.selectedSizeCategory === 3 && model.kidsCategory == 2
            ? kidsUsSizeCategory
            : null"
          :key="index"
        >
          {{
            sizeChartItem.id === model.sizeChart ? sizeChartItem.category : ""
          }}
        </div>
      </div>

      <div
        v-if="model.selectedSizeCategory !== null && model.sizeChart !== null"
      >
        <div
          class="mb-4 "
          v-for="(sizeChartItem, index) in model.selectedSizeCategory === 1
            ? womensSizeChart
            : model.selectedSizeCategory === 2
            ? mensSizeChart
            : model.selectedSizeCategory === 3 && model.kidsCategory === 1
            ? kidsUkSizeCategory
            : model.selectedSizeCategory === 3 && model.kidsCategory === 2
            ? kidsUsSizeCategory
            : null"
          :key="index"
        >
          <div v-viewer>
            <div class="contain" v-if="sizeChartItem.id === model.sizeChart">
              <img
                v-if="sizeChartItem.id === model.sizeChart"
                :src="sizeChartItem.image"
                style="object-fit: contain; height: 100%; width: 100%;"
              />
            </div>
          </div>
        </div>
      </div>

      <span>Unit of Measurement</span>
      <span class="required-field">*</span>
      <v-radio-group
        v-model="model.measurementUnit"
        :error-messages="measurementUnitErrors"
        @input="$v.model.measurementUnit.$touch()"
        @blur="$v.model.measurementUnit.$touch()"
      >
        <v-radio
          v-for="(measure, index) in measurments"
          :key="index"
          :label="measure"
          color="purple darken-3"
          :value="measure"
        ></v-radio>
      </v-radio-group>

      <v-textarea
        class="mt-4 mb-5"
        outlined
        name="input-7-4"
        label="Measurement Chart Detail"
        clearable
        clear-icon="mdi-close-circle"
        color="purple darken-3"
        hint="e.g.: Bust / Chest around 92.5cm"
        v-model="model.measurementChartDetail"
      ></v-textarea>

      <span>Measurement Chart</span>
      <div v-if="measurementChartTotalFileSizeError" style="color:red">
        {{ fileSummationErrorMessage }}
      </div>
      <div v-if="measurementChartFileSizeError" style="color:red">
        {{ maxFileSizeErrorMessage }}
      </div>
      <vue-dropzone
        v-if="isEditAccess"
        class="dropzone mb-5"
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-file-added="addMeasurementChart"
        @vdropzone-removed-file="removeMeasurementChart"
        @vdropzone-error="collectErrors"
      ></vue-dropzone>

      <div
        v-if="!isEditAccess && !model.filesMeasurementChart.length"
        style="color: red"
      >
        * No any file has been uploaded !
      </div>

      <v-row class="mb-10" dense>
        <v-col
          v-for="(file, index) in model.filesMeasurementChart"
          :key="index"
          md="6"
          lg="3"
          sm="3"
          xs="6"
        >
          <v-card>
            <v-img
              v-if="
                file.file.upload_name.split('.')[1] !== 'jpg' &&
                  file.file.upload_name.split('.')[1] !== 'JPG' &&
                  file.file.upload_name.split('.')[1] !== 'png' &&
                  file.file.upload_name.split('.')[1] !== 'jpeg'
              "
              :src="require('../../../assets/folder.png')"
              class="white--text align-end"
              height="150px"
            >
            </v-img>
            <v-img
              v-else
              :src="
                `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
              "
              class="white--text align-end"
              height="150px"
            >
            </v-img>

            <v-card-text>
              <v-row align="center" class="mx-0">
                <div v-text="file.file.title.substr(0, 20)"></div>
              </v-row>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>

              <v-btn
                fab
                x-small
                :href="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                "
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>

              <v-btn
                class="download-delete-btn"
                fab
                x-small
                :href="
                  `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                "
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn
                v-if="isEditAccess"
                fab
                class="download-delete-btn"
                x-small
                @click="deleteFile(file.file.upload_name, 'measurementChart')"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div class="mb-3">Size Measuring Guide Images</div>
      <div v-if="sizeMeasuringGuideTotalFileSizeError" style="color:red">
        {{ fileSummationErrorMessage }}
      </div>
      <div v-if="sizeMeasuringGuideFileSizeError" style="color:red">
        {{ maxFileSizeErrorMessage }}
      </div>
      <vue-dropzone
        v-if="isEditAccess"
        class="dropzone mb-5"
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-file-added="addMeasurementGuideImages"
        @vdropzone-removed-file="removeMeasurementGuideImages"
        @vdropzone-error="collectErrors"
      ></vue-dropzone>

      <div
        v-if="!isEditAccess && !model.filesSizeMeasuringGuide.length"
        style="color: red"
      >
        * No any file has been uploaded !
      </div>

      <v-row dense>
        <v-col
          v-for="(file, index) in model.filesSizeMeasuringGuide"
          :key="index"
          md="6"
          lg="3"
          sm="3"
          xs="6"
        >
          <v-card>
            <v-img
              v-if="
                file.file.upload_name.split('.')[1] !== 'jpg' &&
                  file.file.upload_name.split('.')[1] !== 'JPG' &&
                  file.file.upload_name.split('.')[1] !== 'png' &&
                  file.file.upload_name.split('.')[1] !== 'jpeg'
              "
              :src="require('../../../assets/folder.png')"
              class="white--text align-end"
              height="150px"
            >
            </v-img>
            <v-img
              v-else
              :src="
                `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
              "
              class="white--text align-end"
              height="150px"
            >
            </v-img>

            <v-card-text>
              <v-row align="center" class="mx-0">
                <div v-text="file.file.title.substr(0, 20)"></div>
              </v-row>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>

              <v-btn
                fab
                x-small
                :href="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                "
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>

              <v-btn
                class="download-delete-btn"
                fab
                x-small
                :href="
                  `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                "
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                @click="deleteFile(file.file.upload_name, 'sizeMeasuringGuide')"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="grey lighten-5 mt-10 section">
      <h2 class="mb-2">Sampling</h2>
      <v-divider class="mb-6"></v-divider>
      <size-breakdown
        rowId="samplebulk"
        :showBreakdowns="showBreakdowns"
        :dropdown="true"
        :breakdowns="model.sampleColorSizeBreakdown"
        :sizeChart="getTheSizeChart"
        quantityTitle="Sample Quantity"
        @update-breakdowns="updateSampleBreakdowns"
      >
        <div class="mb-3">Please Upload the Required Color</div>
        <div v-if="totalColorFileSizeError" style="color:red">
          {{ fileSummationErrorMessage }}
        </div>
        <div v-if="colorFileSizeError" style="color:red">
          {{ maxFileSizeErrorMessage }}
        </div>
        <vue-dropzone
          v-if="isEditAccess"
          class="dropzone mb-5"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="addColorFiles"
          @vdropzone-removed-file="removeColorFiles"
          @vdropzone-error="collectErrors"
        ></vue-dropzone>

        <div
          v-if="!isEditAccess && !model.filesColor.length"
          style="color: red"
        >
          * No any file has been uploaded !
        </div>

        <v-row dense>
          <v-col
            v-for="(file, index) in model.filesColor"
            :key="index"
            lg="3"
            md="6"
            sm="3"
            xs="6"
          >
            <v-card>
              <v-img
                v-if="
                  file.file.upload_name.split('.')[1] !== 'jpg' &&
                    file.file.upload_name.split('.')[1] !== 'JPG' &&
                    file.file.upload_name.split('.')[1] !== 'png' &&
                    file.file.upload_name.split('.')[1] !== 'jpeg'
                "
                :src="require('../../../assets/folder.png')"
                class="white--text align-end"
                height="150px"
              >
              </v-img>
              <v-img
                v-else
                :src="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                "
                class="white--text align-end"
                height="150px"
              >
              </v-img>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div v-text="file.file.title.substr(0, 20)"></div>
                </v-row>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  class="download-delete-btn"
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn
                  fab
                  x-small
                  @click="deleteFile(file.file.upload_name, 'colorFiles')"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <div
          class="mb-4 mt-10"
          :style="!showBreakdowns ? 'color:red;' : 'color:black;'"
        >
          Color and Size Break Down for Sampling
          <template v-if="!showBreakdowns">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon class="ml-5" v-on="on" style="color:red;">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
                This field will appear after filling the Size Category,
                Geographical Size
              </span>
            </v-tooltip>
          </template>
        </div>
      </size-breakdown>
      <v-textarea
        class="mt-4 mb-5"
        outlined
        name="input-7-4"
        label="Sample Order Additional Comments"
        clearable
        clear-icon="mdi-close-circle"
        v-model="model.sampleComments"
        color="purple darken-3"
      ></v-textarea>
    </div>
    <div class="grey lighten-5 mt-10 section">
      <h2 class="mb-5">Bulk Order</h2>
      <v-text-field
        type="number"
        min="0"
        v-model="model.targetSalesPrice"
        :error-messages="targetSalesPriceErrors"
        required
        v-on:keypress="InputNumbersOnly"
        @input="$v.model.targetSalesPrice.$touch()"
        @blur="$v.model.targetSalesPrice.$touch()"
        outlined
        dense
        color="purple darken-3"
        prefix="$"
        hint="The price you hope to sell your product"
      >
        <template slot="label">
          <span>Target Sales Price</span>
          <span class="required-field">*</span>
        </template>
      </v-text-field>

      <v-text-field
        type="number"
        min="0"
        v-model="model.targetMakeCost"
        :error-messages="targetMakeCostErrors"
        required
        v-on:keypress="InputNumbersOnly"
        @input="$v.model.targetMakeCost.$touch()"
        @blur="$v.model.targetMakeCost.$touch()"
        outlined
        dense
        color="purple darken-3"
        prefix="$"
        hint="The cost you hope to make the product"
      >
        <template slot="label">
          <span>Target Make Cost</span>
          <span class="required-field">*</span>
        </template>
      </v-text-field>
      <div>
          <span v-if="isInvalidPrice" class="invalid-input">
            Target Make Cost should be less than Target Sales Price
          </span>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <br>
       </div>
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            type="date"
            v-model="model.bulkDeliveryDate"
            :error-messages="bulkDeliveryDateErrors"
            required
            @input="$v.model.bulkDeliveryDate.$touch()"
            @blur="$v.model.bulkDeliveryDate.$touch()"
            outlined
            readonly
            dense
            color="purple darken-3"
            hint="Please select a date after 45 days from requesting date."
            v-bind="attrs"
            v-on="on"
          >
            <template slot="label">
              <span>Expected Bulk Delivery Date</span>
              <span class="required-field">*</span>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          style="margin-top: 0px; margin-bottom:0px;"
          v-model="model.bulkDeliveryDate"
          @input="
            {
              menu1 = false;
            }
          "
        ></v-date-picker>
      </v-menu>

      <div
        class="mb-4 mt-4"
        :style="!showBreakdowns ? 'color:red;' : 'color:black;'"
      >
        Color and Size Breakdown for Bulk
        <template v-if="!showBreakdowns">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon class="ml-5" v-on="on" style="color:red;">
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              This field will appear after filling the Size Category,
              Geographical Size & Color.
            </span>
          </v-tooltip>
        </template>
      </div>
      <div v-if="qtyNotMatchingError" style="color:red">
        Style quantity and bulk size quantities are not matching
      </div>
      <size-breakdown
        rowId="totalbulk"
        :totalError="invalidBulk"
        :isSizeRequired="true"
        :isColorsEditable="false"
        :showBreakdowns="showBreakdowns"
        quantityTitle="Total Quantity"
        :total="inquiryQty"
        :min="0"
        :max="100000000"
        :breakdowns="model.bulkColorSizeBreakdown"
        :sizeChart="getTheSizeChart"
        @update-breakdowns="updateBulkBreakdowns"
      >
      </size-breakdown>
      <v-textarea
        class="mt-4 mb-5"
        outlined
        name="input-7-4"
        label="Bulk Order Additional Comments"
        clearable
        clear-icon="mdi-close-circle"
        v-model="model.bulkComments"
        color="purple darken-3"
      ></v-textarea>
      <div style="display: flex; justify-content: center;">
        <v-alert
          class="mt-5"
          type="error"
          max-width="300px"
          dense
          :value="alert"
          transition="scale-transition"
        >
          Remove unsupported files
        </v-alert>
        <v-alert
          class="mt-5"
          type="error"
          dense
          :value="validationAlert"
          transition="scale-transition"
        >
          Make sure all the required fields are filled properly
        </v-alert>
      </div>

      <!-- <div v-if="loading" class="spinner">
        <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
      </div> -->
      <div class="btn-align">
        <v-btn
          color="green"
          v-if="isEditAccess && !isOwner"
          @click="redirectToCost"
          >Save & Cost Sheet</v-btn
        >
        <v-btn color="primary" v-if="isEditAccess" @click="submit">Save</v-btn>
        <v-btn color="red" v-if="isEditAccess" @click="clearForm()"
          >clear</v-btn
        >
        <v-btn color="blue-grey darken-4" v-if="isEditAccess" @click="reset()"
          >reset
        </v-btn>
        <v-btn
          class="continue_button_color darken-3"
          @click="isEditAccess ? continueStep() : continueToNext()"
          >Continue
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="exceededDialog" persistent max-width="400">
      <v-card>
        <v-card-text class="text-h3">
          Style quantity changed
        </v-card-text>
        <div class="text-h5">
          Would you like to continue
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple darken-1" text @click="resetQuantity">
            Disagree
          </v-btn>
          <v-btn color="purple darken-1" text @click="agreeStatus()">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="subceededDialog" persistent max-width="400">
      <v-card>
        <v-card-text class="text-h3">
          Style quantity changed
        </v-card-text>
        <div class="text-h5">
          Would you like to continue
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple darken-1" text @click="resetQuantity">
            Disagree
          </v-btn>
          <v-btn color="purple darken-1" text @click="agreeStatus()">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500" v-model="commentDialog">
      <Comment
        @comment-submit="commentSubmit"
        @comment-close="commentDialog = false"
        :page="2"
        serviceType="tech_pack"
      />
    </v-dialog>
  </v-form>
</template>

<script>
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
import * as conf from "../../../conf.yml";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import restAdapter from "../../../restAdapter";
import notification from "../../../notification";
import moment from "moment-timezone";
import utils from "../../../utils";
import { resetLeaveAlert, setLeaveAlert } from "../../../utils/confirmBox";
import SizeBreakdown from "../../../components/SizeBreakdown.vue";
import Comment from "../Comment.vue";

Vue.use(VueViewer);

const validateDate = (value) => {
  if (typeof value === "undefined" || value === null || value === "") {
    return true;
  } else {
    const startDate = moment(value, "YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");

    const elapsedTime = startDate.diff(today, "days");

    if (elapsedTime > 45) {
      return true;
    } else {
      return false;
    }
  }
};

const validateQuantity = (value) => {
  if (!value) {
    return false;
  }
  value = parseInt(value);
  if (value >= 50 && value <= 100000000) {
    return true;
  }
  return false;
};

export default {
  name: "Production",
  mixins: [validationMixin],
  components: {
    SizeBreakdown,
    vueDropzone: vue2Dropzone,
    Comment,
  },

  data() {
    return {
      cards: [
        {
          title: "Pre-fab homes",
          src: require("../../../assets/folder.png"),
          flex: 2,
        },
      ],
      redirectToCostSheet: false,
      commentDialog: false,
      isSubmit: false,
      isContinue: false,
      showProgress: false,
      progress: 0,
      invalidBulk: false,
      qtyNotMatchingError: false,
      menu1: false,
      sampleQtySaveError: false,
      totalQtySaveError: false,
      baseUrl: "",
      inquiryId: "",
      loading: false,
      fileUploadErrors: false,
      unsupportedFiles: [],
      alert: false,
      validationAlert: false,
      exceededDialog: false,
      subceededDialog: false,
      inquiryQty: null,
      isEdit: true,
      oldSampleBreakdown: [],
      oldBulkBreakdown: [],
      dropzoneOptions: {
        url: "https://",
        autoProcessQueue: false,
        thumbnailWidth: 200, // px
        thumbnailHeight: 200,
        maxFilesize: 50,
        dictDefaultMessage:
          "<div style='filter: grayscale(1)'>📤</div> Drag and Drop (or) Choose File(s)",

        acceptedFiles: utils.acceptedFileTypes,
        addRemoveLinks: true,
      },
      sampleSelect: [],
      sizeCategory: [
        { id: 1, category: "Women" },
        { id: 2, category: "Men" },
        { id: 3, category: "Kids" },
        { id: 4, category: "Other" },
      ],
      mensSizeChart: [
        {
          id: 1,
          category: "UK Size Chart",
          sizes: ["UK 36", "UK 38", "UK 40", "UK 42", "UK 44", "UK 46"],
          image: require(`../../../assets/size-charts/MEN_S_UK_SIZE_CHART.png`),
        },
        {
          id: 2,
          category: "Europe Size Chart",
          sizes: [
            "EU 46",
            "EU 48",
            "EU 50",
            "EU 52",
            "EU 54",
            "EU 56",
            "EU 58",
            "EU 60",
          ],
          image: require(`../../../assets/size-charts/MEN_S_EUROPEAN_SIZE_CHART.png`),
        },
        {
          id: 3,
          category: "US Size Chart",
          sizes: [
            "US 34",
            "US 36",
            "US 38",
            "US 40",
            "US 42",
            "US 44",
            "US 46",
            "US 48",
          ],
          image: require(`../../../assets/size-charts/MEN_S_AU___US_SIZE_CHART.png`),
        },
        {
          id: 4,
          category: "AUS Size Chart",
          sizes: [
            "AUS 34",
            "AUS 36",
            "AUS 38",
            "AUS 40",
            "AUS 42",
            "AUS 44",
            "AUS 46",
            "AUS 48",
          ],
          image: require(`../../../assets/size-charts/MEN_S_AU___US_SIZE_CHART.png`),
        },
      ],
      womensSizeChart: [
        {
          id: 1,
          category: "UK Size Chart",
          sizes: ["UK 8", "UK 10", "UK 12", "UK 14", "UK 16", "UK 18"],
          image: require(`../../../assets/size-charts/WOMEN_S_UK_SIZE_CHART.png`),
        },
        {
          id: 2,
          category: "Europe Size Chart",
          sizes: [
            "EU 34",
            "EU 36",
            "EU 38",
            "EU 40",
            "EU 42",
            "EU 44",
            "EU 46",
            "EU 48",
            "EU 50",
            "EU 52",
            "EU 54",
          ],
          image: require(`../../../assets/size-charts/WOMEN_S_EUROPEAN_SIZE_CHART.png`),
        },
        {
          id: 3,
          category: "US Size Chart",
          sizes: ["US 2", "US 4", "US 6", "US 8", "US 10", "US 12", "US 14"],
          image: require(`../../../assets/size-charts/WOMEN_S_AU___US_SIZE_CHART.png`),
        },
        {
          id: 4,
          category: "AUS Size Chart",
          sizes: [
            "AUS 4",
            "AUS 6",
            "AUS 8",
            "AUS 10",
            "AUS 12",
            "AUS 14",
            "AUS 16",
            "AUS 18",
          ],
          image: require(`../../../assets/size-charts/WOMEN_S_AU___US_SIZE_CHART.png`),
        },
      ],
      kidsSizeChart: [
        { id: 1, category: "UK Size Chart" },
        { id: 2, category: "US Size Chart" },
      ],

      kidsUkSizeCategory: [
        {
          id: 1,
          category: "Infants ( New born - 24 M )",
          sizes: [
            "New Born",
            "UK 3M",
            "UK 6M",
            "UK 9M",
            "UK 12M",
            "UK 18M",
            "UK 24M",
          ],
          image: require(`../../../assets/size-charts/INFANT_UK_NEWBORN_24_M.png`),
        },
        {
          id: 2,
          category: "Toddler 3T - 24 T",
          sizes: ["UK 3T", "UK 4T"],
          image: require(`../../../assets/size-charts/TODDLER_UK_3T_4T.png`),
        },
        {
          id: 3,
          category: "Boy/Girl 5yrs - 7 yrs",
          sizes: ["UK 5", "UK 6", "UK 7"],
          image: require(`../../../assets/size-charts/BOY___GIRL_UK_5_YRS_7_YRS.png`),
        },
        {
          id: 4,
          category: "Girl 8 yrs - 16 yrs",
          sizes: ["UK 8", "UK 10", "UK 12", "UK 14", "UK 16"],
          image: require(`../../../assets/size-charts/GIRL_UK_8_YRS_16_YRS.png`),
        },
        {
          id: 5,
          category: "Boy 8 yrs - 16 yrs",
          sizes: ["UK 8", "UK 10", "UK 12", "UK 14", "UK 16"],
          image: require(`../../../assets/size-charts/BOYS_UK_8YRS_16_YRS.png`),
        },
      ],
      kidsUsSizeCategory: [
        {
          id: 1,
          category: "Infants ( New born - 24 M )",
          sizes: [
            "PREEMIREE",
            "NEW BORN",
            "US 3M",
            "US 6M",
            "US 9M",
            "US 12M",
            "US 18M",
            "US 24M",
          ],
          image: require(`../../../assets/size-charts/INFANT_US_PREMIREE_24_M.png`),
        },
        {
          id: 2,
          category: "Boy/Girl 2 yrs - 6x yrs",
          sizes: ["US 2", "US 3", "US 4", "US 5", "US 6", "US 6X"],
          image: require(`../../../assets/size-charts/BOY___GIRL_US_2YRS_6X_YRS.png`),
        },
        {
          id: 3,
          category: "Girl 7 yrs - 20 yrs",
          sizes: [
            "US 7",
            "US 8",
            "US 10",
            "US 12",
            "US 14",
            "US 16",
            "US 18",
            "US 20",
          ],
          image: require(`../../../assets/size-charts/GIRL_US_7_YRS_20_YRS.png`),
        },
        {
          id: 4,
          category: "Boy 7 yrs - 20 yrs",
          sizes: [
            "US 7",
            "US 8",
            "US 10",
            "US 12",
            "US 14",
            "US 16",
            "US 18",
            "US 20",
          ],
          image: require(`../../../assets/size-charts/BOYS_US_7_YRS_20_YRS.png`),
        },
      ],

      measurments: ["CM", "INCHES"],
      measurementChartTotalFileSizeError: false,
      measurementChartFileSizeError: false,
      sizeMeasuringGuideFileSizeError: false,
      sizeMeasuringGuideTotalFileSizeError: false,
      colorFileSizeError: false,
      totalColorFileSizeError: false,
      totalFileSize: 102000000,
      maximumFileSize: 52000000,
      fileSummationErrorMessage:
        "Selected files are exceeding the maximum file summation limit (100mb)",
      maxFileSizeErrorMessage:
        "One or more files are too large to upload (50mb)",

      model: {
        sampleQunatity: null,
        selectedSizeCategory: null,
        sizeChart: null,
        kidsCategory: null,
        otherCategory: "",
        sizeMeasuringGuide: [],
        filesSizeMeasuringGuide: [],
        measurementUnit: null,
        colorFiles: [],
        filesColor: [],
        measurementChart: [],
        filesMeasurementChart: [],
        measurementChartDetail: "",
        otherSizes: [
          {
            size: "",
          },
        ],
        targetSalesPrice: null,
        targetMakeCost: null,
        totalQuantity: null,
        bulkDeliveryDate: null,
        sampleColorSizeBreakdown: [],
        bulkColorSizeBreakdown: [],
        bulkComments: "",
        sampleComments: "",
      },
      ignoreFormEdit: false,
    };
  },

  validations: {
    model: {
      otherSizes: {
        $each: {
          size: {
            required: requiredIf(function() {
              if (
                this.model.sizeChart === null &&
                this.model.selectedSizeCategory === 4
              ) {
                return true;
              } else {
                return false;
              }
            }),
          },
        },
      },
      targetSalesPrice: { required },
      targetMakeCost: { required },
      bulkDeliveryDate: { required, validateDate },
      selectedSizeCategory: { required },
      measurementUnit: { required },
      sizeChart: {
        required: requiredIf(function() {
          if (
            this.model.sizeChart === null &&
            this.model.selectedSizeCategory !== 4
          ) {
            return true;
          } else {
            return false;
          }
        }),
      },
      otherCategory: {
        required: requiredIf(function() {
          if (this.model.selectedSizeCategory === 4) {
            return true;
          } else {
            return false;
          }
        }),
      },
      kidsCategory: {
        required: requiredIf(function() {
          if (
            this.model.kidsCategory === null &&
            this.model.selectedSizeCategory === 3
          ) {
            return true;
          } else {
            return false;
          }
        }),
      },
    },
  },

  props: {
    isOwner: {
      type: Boolean,
    },
    isEditAccess: {
      type: Boolean,
    },
  },

  methods: {
    updateSampleBreakdowns(breakdowns) {
      this.model.sampleColorSizeBreakdown = breakdowns;
      this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(
        (breakdown) => breakdown
      );
    },
    updateBulkBreakdowns(breakdowns) {
      this.model.bulkColorSizeBreakdown = breakdowns;
      this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(
        (breakdown) => breakdown
      );
    },
    isBreakdownChanged() {
      let isSampleBreakdownChanged = false;
      let isBulkBreakdownChanged = false;

      if (
        this.oldSampleBreakdown.length !==
        this.model.sampleColorSizeBreakdown.length
      ) {
        isSampleBreakdownChanged = true;
      } else {
        for (let i = 0; i < this.oldSampleBreakdown.length; i++) {
          if (
            this.oldSampleBreakdown[i].sizeAndQuantity.length !==
            this.model.sampleColorSizeBreakdown[i].sizeAndQuantity.length
          ) {
            isSampleBreakdownChanged = true;
            break;
          } else {
            for (
              let index = 0;
              index < this.oldSampleBreakdown[i].sizeAndQuantity.length;
              index++
            ) {
              const isDiff =
                this.oldSampleBreakdown[i].sizeAndQuantity[index].quantity !==
                this.model.sampleColorSizeBreakdown[i].sizeAndQuantity[index]
                  .quantity;
              if (isDiff) {
                isSampleBreakdownChanged = true;
                break;
              }
            }
          }
        }
      }

      if (
        this.oldBulkBreakdown.length !==
        this.model.bulkColorSizeBreakdown.length
      ) {
        isBulkBreakdownChanged = true;
      } else {
        for (let i = 0; i < this.oldBulkBreakdown.length; i++) {
          if (
            this.oldBulkBreakdown[i].sizeAndQuantity.length !==
            this.model.bulkColorSizeBreakdown[i].sizeAndQuantity.length
          ) {
            isBulkBreakdownChanged = true;
            break;
          } else {
            for (
              let index = 0;
              index < this.oldBulkBreakdown[i].sizeAndQuantity.length;
              index++
            ) {
              const isDiff =
                this.oldBulkBreakdown[i].sizeAndQuantity[index].quantity !==
                this.model.bulkColorSizeBreakdown[i].sizeAndQuantity[index]
                  .quantity;
              if (isDiff) {
                isBulkBreakdownChanged = true;
                break;
              }
            }
          }
        }
      }
      return isBulkBreakdownChanged || isSampleBreakdownChanged;
    },
    continueToNext() {
      this.$emit("set-done");
      this.redirectToCostSheet = false;
    },
    continueStep() {
      this.isContinue = true;
      this.redirectToCostSheet = false;
      const bulkTotal = this.calculateTotalQty(
        this.model.bulkColorSizeBreakdown
      );
      this.model.totalQuantity = bulkTotal;

      this.invalidBulk = !validateQuantity(this.model.totalQuantity);
      if (this.invalidBulk) {
        this.invalidBulk = true;
        document.getElementById(`totalbulk-total`).scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "center",
        });
        return;
      }

      if (!bulkTotal && bulkTotal != this.inquiryQty) {
        this.qtyNotMatchingError = true;
        return;
      }

      if (this.model.totalQuantity > this.inquiryQty) {
        this.exceededDialog = true;
        return;
      }

      if (this.model.totalQuantity < this.inquiryQty) {
        this.subceededDialog = true;
        return;
      }

      this.checkSampleBulkList();
      if (this.sampleQtySaveError) {
        return;
      }

      this.checkTotalBulkList();
      if (this.totalQtySaveError) {
        return;
      }

      if (
        this.measurementChartTotalFileSizeError ||
        this.measurementChartFileSizeError ||
        this.sizeMeasuringGuideFileSizeError ||
        this.sizeMeasuringGuideTotalFileSizeError ||
        this.colorFileSizeError ||
        this.totalColorFileSizeError
      ) {
        return;
      }

      this.$v.model.$touch();

      this.$nextTick(() => {
        if (document.querySelector(".v-messages__message")) {
          const domRect = document
            .querySelector(".v-messages__message")
            .getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      });

      if (this.$v.model.$invalid) {
        this.$emit("error");
        return;
      }
      if (this.unsupportedFiles.length > 0) {
        this.$emit("error");
        this.alert = true;
        return;
      }
      this.submit();
    },
    commentSubmit() {
      this.commentDialog = false;
      this.isSubmit = true;
      if (this.redirectToCostSheet) {
        this.submitAndLeave();
      } else {
        this.submit();
        this.continueNextPage();
      }
    },
    continueNextPage() {
      if (this.isContinue) {
        this.isContinue = false;
        this.$emit("set-done");
      }
    },
    addComment() {
      if (!this.isOwner) {
        this.commentDialog = true;
      } else {
        this.commentSubmit();
      }
    },
    redirectToCost() {
      if (this.$store.state.routeLeaveAlert) {
        this.redirectToCostSheet = true;
        this.submitAndLeave();
      } else {
        this.$router.push({ name: "Style Costing Sheet" });
      }
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    reset() {
      this.$emit("reset-status", false);
    },
    clearForm() {
      this.$v.model.$reset();

      this.model.sampleQunatity = null;
      this.model.selectedSizeCategory = null;
      this.model.sizeChart = null;
      this.model.kidsCategory = null;
      this.model.targetSalesPrice = null;
      this.model.targetMakeCost = null;
      this.model.totalQuantity = null;
      this.model.bulkDeliveryDate = null;
      this.model.measurementUnit = null;
      (this.model.otherCategory = ""),
        (this.model.bulkComments = ""),
        (this.model.sampleComments = ""),
        (this.model.measurementChartDetail = "");
      this.model.filesMeasurementChart.splice(
        0,
        this.model.filesMeasurementChart.length
      );
      this.model.measurementChart.splice(0, this.model.measurementChart.length);
      this.model.sizeMeasuringGuide.splice(
        0,
        this.model.sizeMeasuringGuide.length
      );

      this.model.filesSizeMeasuringGuide.splice(
        0,
        this.model.filesSizeMeasuringGuide.length
      );

      this.model.colorFiles.splice(0, this.model.colorFiles.length);

      this.model.filesColor.splice(0, this.model.filesColor.length);

      this.model.otherSizes.splice(0, this.model.otherSizes.length);

      this.model.sampleColorSizeBreakdown.splice(
        0,
        this.model.sampleColorSizeBreakdown.length
      );

      this.model.bulkColorSizeBreakdown.splice(
        0,
        this.model.bulkColorSizeBreakdown.length
      );

      this.model.otherSizes.push({
        size: "",
      });
    },
    deleteFile(uniqueId, type) {
      restAdapter.delete("/api/tech_pack/file_delete/" + uniqueId).then(() => {
        notification.success("File deleted successfully");
        if (type === "measurementChart") {
          for (
            let index = 0;
            index < this.model.filesMeasurementChart.length;
            index++
          ) {
            if (
              this.model.filesMeasurementChart[index].file.upload_name ==
              uniqueId
            ) {
              this.model.filesMeasurementChart.splice(index, 1);
            }
          }
        } else if (type === "sizeMeasuringGuide") {
          for (
            let index = 0;
            index < this.model.filesSizeMeasuringGuide.length;
            index++
          ) {
            if (
              this.model.filesSizeMeasuringGuide[index].file.upload_name ==
              uniqueId
            ) {
              this.model.filesSizeMeasuringGuide.splice(index, 1);
            }
          }
        } else if (type === "colorFiles") {
          for (let index = 0; index < this.model.filesColor.length; index++) {
            if (this.model.filesColor[index].file.upload_name == uniqueId) {
              this.model.filesColor.splice(index, 1);
            }
          }
        }
      });
    },
    selectedSizeCategoryWatcher(newValue, oldValue) {
      this.model.sampleColorSizeBreakdown.splice(
        0,
        this.model.sampleColorSizeBreakdown.length
      );

      this.model.bulkColorSizeBreakdown.splice(
        0,
        this.model.bulkColorSizeBreakdown.length
      );

      this.model.sizeChart = null;
      this.model.kidsCategory = null;
      this.model.sampleQunatity = null;
      this.model.totalQuantity = null;
      this.model.otherCategory = "";

      this.model.otherSizes.splice(0, this.model.otherSizes.length);

      if (newValue === 4) {
        this.model.sampleColorSizeBreakdown.push({
          color: "",
          sizeAndQuantity: [],
        });
        this.model.bulkColorSizeBreakdown.push({
          color: "",
          sizeAndQuantity: [],
        });
        this.model.otherSizes = [
          {
            size: "",
          },
        ];
      }
    },
    sizeChartWatcher() {
      this.model.sampleColorSizeBreakdown.splice(
        0,
        this.model.sampleColorSizeBreakdown.length
      );

      this.model.bulkColorSizeBreakdown.splice(
        0,
        this.model.bulkColorSizeBreakdown.length
      );
      this.model.sampleQunatity = null;
      this.model.totalQuantity = null;

      this.model.sampleColorSizeBreakdown.push({
        color: "",
        sizeAndQuantity: [],
      });

      this.model.bulkColorSizeBreakdown.push({
        color: "",
        sizeAndQuantity: [],
      });

      if (this.model.selectedSizeCategory === 1) {
        if (this.model.sizeChart === 1) {
          this.womensSizeChart[0].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });

            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 2) {
          this.womensSizeChart[1].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });

            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 3) {
          this.womensSizeChart[2].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });

            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 4) {
          this.womensSizeChart[3].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });

            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        }
      } else if (this.model.selectedSizeCategory === 2) {
        if (this.model.sizeChart === 1) {
          this.mensSizeChart[0].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });

            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 2) {
          this.mensSizeChart[1].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });

            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 3) {
          this.mensSizeChart[2].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });

            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 4) {
          this.mensSizeChart[3].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });

            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        }
      } else if (this.model.selectedSizeCategory === 3) {
        if (this.model.kidsCategory === 1) {
          if (this.model.sizeChart === 1) {
            this.kidsUkSizeCategory[0].sizes.map((size, index) => {
              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 2) {
            this.kidsUkSizeCategory[1].sizes.map((size, index) => {
              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 3) {
            this.kidsUkSizeCategory[2].sizes.map((size, index) => {
              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 4) {
            this.kidsUkSizeCategory[3].sizes.map((size, index) => {
              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 5) {
            this.kidsUkSizeCategory[4].sizes.map((size, index) => {
              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          }
        } else if (this.model.kidsCategory === 2) {
          if (this.model.sizeChart === 1) {
            this.kidsUsSizeCategory[0].sizes.map((size, index) => {
              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 2) {
            this.kidsUsSizeCategory[1].sizes.map((size, index) => {
              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 3) {
            this.kidsUsSizeCategory[2].sizes.map((size, index) => {
              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 4) {
            this.kidsUsSizeCategory[3].sizes.map((size, index) => {
              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          }
        }
      }
    },

    kidsCategoryWatcher() {
      this.model.sampleColorSizeBreakdown = [];
      this.model.bulkColorSizeBreakdown = [];
      this.model.sizeChart = null;
      this.model.sampleQunatity = null;
      this.model.totalQuantity = null;
    },
    collectErrors(file, message, xhr) {
      this.unsupportedFiles.push({ file });
    },
    addMeasurementChart: function(file) {
      if (file.size > this.maximumFileSize) {
        this.measurementChartFileSizeError = true;
      }

      this.model.measurementChart.push({ file });

      let fileSize = 0;

      for (let index = 0; index < this.model.measurementChart.length; index++) {
        fileSize = fileSize + this.model.measurementChart[index].file.size;
      }

      this.measurementChartTotalFileSizeError =
        fileSize > this.totalFileSize ? true : false;
    },

    removeMeasurementChart(file) {
      let fileSize = 0;

      for (let index = 0; index < this.model.measurementChart.length; index++) {
        if (this.model.measurementChart[index].file.name === file.name) {
          this.model.measurementChart.splice(index, 1);
        }
        fileSize =
          fileSize +
          (this.model.measurementChart[index]
            ? this.model.measurementChart[index].file.size
            : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.measurementChartTotalFileSizeError =
        fileSize > this.totalFileSize ? true : false;
      this.measurementChartFileSizeError =
        this.unsupportedFiles.length > 0 ? true : false;
    },

    addMeasurementGuideImages(file) {
      if (file.size > this.maximumFileSize) {
        this.sizeMeasuringGuideFileSizeError = true;
      }

      this.model.sizeMeasuringGuide.push({ file });

      let fileSize = 0;
      for (
        let index = 0;
        index < this.model.sizeMeasuringGuide.length;
        index++
      ) {
        fileSize = fileSize + this.model.sizeMeasuringGuide[index].file.size;
      }
      this.sizeMeasuringGuideTotalFileSizeError =
        fileSize > this.totalFileSize ? true : false;
    },

    removeMeasurementGuideImages(file) {
      let fileSize = 0;
      for (
        let index = 0;
        index < this.model.sizeMeasuringGuide.length;
        index++
      ) {
        if (this.model.sizeMeasuringGuide[index].file.name === file.name) {
          this.model.sizeMeasuringGuide.splice(index, 1);
        }
        fileSize =
          fileSize +
          (this.model.sizeMeasuringGuide[index]
            ? this.model.sizeMeasuringGuide[index].file.size
            : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.sizeMeasuringGuideTotalFileSizeError =
        fileSize > this.totalFileSize ? true : false;
      this.sizeMeasuringGuideFileSizeError =
        this.unsupportedFiles.length > 0 ? true : false;
    },
    addColorFiles(file) {
      if (file.size > this.maximumFileSize) {
        this.colorFileSizeError = true;
      }

      this.model.colorFiles.push({ file });

      let fileSize = 0;
      for (let index = 0; index < this.model.colorFiles.length; index++) {
        fileSize = fileSize + this.model.colorFiles[index].file.size;
      }
      this.totalColorFileSizeError =
        fileSize > this.totalFileSize ? true : false;
    },
    removeColorFiles(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.colorFiles.length; index++) {
        if (this.model.colorFiles[index].file.name === file.name) {
          this.model.colorFiles.splice(index, 1);
        }
        fileSize =
          fileSize +
          (this.model.colorFiles[index]
            ? this.model.colorFiles[index].file.size
            : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.totalColorFileSizeError =
        fileSize > this.totalFileSize ? true : false;
      this.colorFileSizeError = this.unsupportedFiles.length > 0 ? true : false;
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    getCategories() {
      return restAdapter.get("/api/inquire_categories");
    },
    addSize() {
      this.model.otherSizes.push({
        size: "",
      });

      this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(
        (breakdown) => {
          return {
            ...breakdown,
            sizeAndQuantity: this.model.otherSizes.map((size, index) => {
              if (!breakdown.sizeAndQuantity[index]) {
                return { size: size.size, quantity: 0 };
              }

              return { ...breakdown.sizeAndQuantity[index] };
            }),
          };
        }
      );

      this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(
        (breakdown) => {
          return {
            ...breakdown,
            sizeAndQuantity: this.model.otherSizes.map((size, index) => {
              if (!breakdown.sizeAndQuantity[index]) {
                return { size: size.size, quantity: 0 };
              }

              return { ...breakdown.sizeAndQuantity[index] };
            }),
          };
        }
      );
    },

    addOtherSizesSampleBulk(size, index) {
      this.model.totalQuantity = null;
      this.model.sampleQunatity = null;
      if (this.model.sampleColorSizeBreakdown.length === 0) {
        this.model.sampleColorSizeBreakdown.push({
          color: "",
          sizeAndQuantity: [],
        });
        this.model.otherSizes = [
          {
            size: "",
          },
        ];
      }
      if (this.model.bulkColorSizeBreakdown.length === 0) {
        this.model.bulkColorSizeBreakdown.push({
          color: "",
          sizeAndQuantity: [],
        });
        this.model.otherSizes = [
          {
            size: "",
          },
        ];
      }
      for (let i = 0; i < this.model.sampleColorSizeBreakdown.length; i++) {
        this.model.sampleColorSizeBreakdown[i].sizeAndQuantity[index] = {
          size: size,
          quantity: 0,
        };
      }

      for (let i = 0; i < this.model.bulkColorSizeBreakdown.length; i++) {
        this.model.bulkColorSizeBreakdown[i].sizeAndQuantity[index] = {
          size: size,
          quantity: 0,
        };
      }

      this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(
        (breakdown) => breakdown
      );
      this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(
        (breakdown) => breakdown
      );
    },

    addSampleAndBulkColor(color, index) {
      this.model.sampleColorSizeBreakdown[index].color = color;
      this.model.bulkColorSizeBreakdown[index].color = color;
    },
    calculateSampleQuantity(value_index = null) {
      this.model.sampleQunatity = 0;
      this.model.sampleColorSizeBreakdown.forEach((element) => {
        element.sizeAndQuantity.forEach((item) => {
          this.model.sampleQunatity = this.model.sampleQunatity + item.quantity;
        });
      });
      if (value_index === 0 || value_index) {
        this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(
          (item, index) => {
            if (index == value_index) {
              return {
                ...this.model.sampleColorSizeBreakdown[index],
                error: false,
              };
            }
            return { ...this.model.sampleColorSizeBreakdown[index] };
          }
        );
      }
      return;
    },

    agreeStatus() {
      this.inquiryQty = this.model.totalQuantity;
      this.subceededDialog = false;
      this.exceededDialog = false;
      this.qtyNotMatchingError = false;
      this.submit();
    },

    calculateTotalQty(breakdowns) {
      let totalBulkValue = 0;
      breakdowns.forEach((element) => {
        element.sizeAndQuantity.forEach((item) => {
          let totalValue = null;
          if (item.quantity) {
            totalValue = parseFloat(item.quantity);
          }
          totalBulkValue = totalBulkValue + totalValue;
        });
      });
      return totalBulkValue;
    },

    calculateTotalQuantity(value_index = null) {
      this.model.totalQuantity = 0;
      this.model.bulkColorSizeBreakdown.forEach((element) => {
        element.sizeAndQuantity.forEach((item) => {
          let totalValue = null;
          if (item.quantity) {
            totalValue = parseFloat(item.quantity);
          }
          this.model.totalQuantity = this.model.totalQuantity + totalValue;
        });
      });
      if (value_index === 0 || value_index) {
        this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(
          (item, index) => {
            if (index == value_index) {
              return {
                ...this.model.bulkColorSizeBreakdown[index],
                error: false,
              };
            }
            return { ...this.model.bulkColorSizeBreakdown[index] };
          }
        );
      }
      return;
    },
    removeSize(index) {
      this.model.otherSizes.splice(index, 1);

      this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(
        (breakdown) => {
          return {
            ...breakdown,
            sizeAndQuantity: breakdown.sizeAndQuantity.filter(
              (size, position) => index !== position
            ),
          };
        }
      );

      this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(
        (breakdown) => {
          return {
            ...breakdown,
            sizeAndQuantity: breakdown.sizeAndQuantity.filter(
              (size, position) => index !== position
            ),
          };
        }
      );

      this.calculateSampleQuantity();
      this.calculateTotalQuantity();
    },
    sampleSelectList() {
      for (let i = 0; i < 6; i++) {
        this.sampleSelect.push(i);
      }
    },
    checkSampleBulkList() {
      if (!this.model.sampleColorSizeBreakdown.length) {
        this.sampleQtySaveError = false;
        return;
      }

      for (let i = 0; i < this.model.sampleColorSizeBreakdown.length; i++) {
        let allNull = true;
        this.sampleQtySaveError = false;

        if (!this.model.sampleColorSizeBreakdown[i].color) {
          this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(
            (item, index) => {
              if (index == i) {
                document.getElementById(`samplebulk-${index}`).scrollIntoView({
                  behavior: "smooth",
                  inline: "center",
                  block: "center",
                });
                return {
                  ...this.model.sampleColorSizeBreakdown[index],
                  colorError: true,
                };
              }
              return { ...this.model.sampleColorSizeBreakdown[index] };
            }
          );
          this.sampleQtySaveError = true;
          return;
        }

        for (
          let j = 0;
          j < this.model.sampleColorSizeBreakdown[i].sizeAndQuantity.length;
          j++
        ) {
          if (
            this.model.sampleColorSizeBreakdown[i].sizeAndQuantity[j]
              .quantity === 0 ||
            this.model.sampleColorSizeBreakdown[i].sizeAndQuantity[j].quantity
          ) {
            allNull = false;
            this.sampleQtySaveError = false;
          }
        }
        if (allNull) {
          this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(
            (item, index) => {
              if (index == i) {
                document.getElementById(`samplebulk-${index}`).scrollIntoView({
                  behavior: "smooth",
                  inline: "center",
                  block: "center",
                });
                return {
                  ...this.model.sampleColorSizeBreakdown[index],
                  error: true,
                };
              }
              return { ...this.model.sampleColorSizeBreakdown[index] };
            }
          );
          this.sampleQtySaveError = true;
          return;
        }
      }
    },

    checkTotalBulkList() {
      for (let i = 0; i < this.model.bulkColorSizeBreakdown.length; i++) {
        let allNull = true;
        this.totalQtySaveError = false;

        if (!this.model.bulkColorSizeBreakdown[i].color) {
          this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(
            (item, index) => {
              if (index == i) {
                document.getElementById(`totalbulk-${index}`).scrollIntoView({
                  behavior: "smooth",
                  inline: "center",
                  block: "center",
                });
                return {
                  ...this.model.bulkColorSizeBreakdown[index],
                  colorError: true,
                };
              }
              return { ...this.model.bulkColorSizeBreakdown[index] };
            }
          );
          this.totalQtySaveError = true;
          return;
        }

        for (
          let j = 0;
          j < this.model.bulkColorSizeBreakdown[i].sizeAndQuantity.length;
          j++
        ) {
          if (
            this.model.bulkColorSizeBreakdown[i].sizeAndQuantity[j].quantity &&
            parseInt(
              this.model.bulkColorSizeBreakdown[i].sizeAndQuantity[j].quantity
            )
          ) {
            allNull = false;
            this.totalQtySaveError = false;
          }
        }

        if (allNull) {
          this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(
            (item, index) => {
              if (index == i) {
                document.getElementById(`totalbulk-${index}`).scrollIntoView({
                  behavior: "smooth",
                  inline: "center",
                  block: "center",
                });
                return {
                  ...this.model.bulkColorSizeBreakdown[index],
                  error: true,
                };
              }
              return { ...this.model.bulkColorSizeBreakdown[index] };
            }
          );
          this.totalQtySaveError = true;
          return;
        }
      }
    },
    verifySubmission() {
      const sampleTotal = this.calculateTotalQty(
        this.model.sampleColorSizeBreakdown
      );
      const bulkTotal = this.calculateTotalQty(
        this.model.bulkColorSizeBreakdown
      );
      this.model.totalQuantity = bulkTotal;

      this.invalidBulk = !validateQuantity(this.model.totalQuantity);
      if (this.invalidBulk) {
        this.invalidBulk = true;
        document.getElementById(`totalbulk-total`).scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "center",
        });
        return;
      }

      if (!bulkTotal && bulkTotal != this.inquiryQty) {
        this.qtyNotMatchingError = true;
        return;
      }

      if (this.model.totalQuantity > this.inquiryQty) {
        this.exceededDialog = true;
        return;
      }

      if (this.model.totalQuantity < this.inquiryQty) {
        this.subceededDialog = true;
        return;
      }

      this.checkSampleBulkList();
      if (this.sampleQtySaveError) {
        return;
      }

      this.checkTotalBulkList();
      if (this.totalQtySaveError) {
        return;
      }

      this.$v.model.$touch();

      if (
        this.measurementChartTotalFileSizeError ||
        this.measurementChartFileSizeError ||
        this.sizeMeasuringGuideFileSizeError ||
        this.sizeMeasuringGuideTotalFileSizeError ||
        this.colorFileSizeError ||
        this.totalColorFileSizeError
      ) {
        return;
      }

      this.$nextTick(() => {
        if (document.querySelector(".v-messages__message")) {
          const domRect = document
            .querySelector(".v-messages__message")
            .getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      });

      if (this.$v.model.$invalid) {
        return;
      }

      if (this.unsupportedFiles.length > 0) {
        this.alert = true;
        return;
      }
      if (!this.isSubmit && this.$store.state.routeLeaveAlert) {
        this.addComment();
        return;
      }
      this.isSubmit = false;
      const formData = new FormData();
      for (let index = 0; index < this.model.measurementChart.length; index++) {
        formData.append(
          "measurement_chart[]",
          this.model.measurementChart[index].file
        );
      }
      for (
        let index = 0;
        index < this.model.sizeMeasuringGuide.length;
        index++
      ) {
        formData.append(
          "measurement_guide[]",
          this.model.sizeMeasuringGuide[index].file
        );
      }

      for (let index = 0; index < this.model.colorFiles.length; index++) {
        formData.append("color_files[]", this.model.colorFiles[index].file);
      }

      formData.append("sample_quantity", sampleTotal);
      formData.append("total_quantity", bulkTotal);
      formData.append("target_sales_price", this.model.targetSalesPrice);
      formData.append("target_make_cost", this.model.targetMakeCost);
      formData.append("delivery_date", this.model.bulkDeliveryDate);
      formData.append("size_category", this.model.selectedSizeCategory);
      formData.append("geographical_size_category", this.model.sizeChart);
      formData.append(
        "kids_geographical_sub_category",
        this.model.kidsCategory
      );
      formData.append("other_size_category", this.model.otherCategory);
      formData.append("measurement_unit", this.model.measurementUnit);
      formData.append(
        "measurement_chart_details",
        this.model.measurementChartDetail
      );
      formData.append("sample_additional_comments", this.model.sampleComments);
      formData.append("bulk_additional_comments", this.model.bulkComments);
      formData.append(
        "sample_breakdowns",
        JSON.stringify(this.model.sampleColorSizeBreakdown)
      );
      formData.append(
        "bulk_breakdowns",
        JSON.stringify(this.model.bulkColorSizeBreakdown)
      );
      formData.append("isBreakdownUpdated", this.isBreakdownChanged());
      this.showProgress = true;
      document.getElementById("progress-indicator").scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
      return formData;
    },
    async submitAndLeave() {
      const formData = this.verifySubmission();
      if (!formData) return;
      const response = await restAdapter.post(
        `/api/inquiries/${this.inquiryId}/tech_pack/production`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );
      resetLeaveAlert();
      this.ignoreFormEdit = true;
      this.$v.model.$reset();
      this.redirectToCostSheet = false;
      this.$router.push({ name: "Style Costing Sheet" });
    },
    submit() {
      this.redirectToCostSheet = false;
      const formData = this.verifySubmission();
      if (!formData) return;
      restAdapter
        .post(
          `/api/inquiries/${this.inquiryId}/tech_pack/production`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              this.progress = Math.floor(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          }
        )
        .then((response) => {
          this.showProgress = false;
          this.reset();
          notification.success("Production Details Saved Successfully");
        })
        .catch((error) => {
          this.showProgress = false;
          this.loading = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
      resetLeaveAlert();
      this.ignoreFormEdit = true;
      this.$v.model.$reset();
      this.continueNextPage();
    },

    getProduction() {
      return restAdapter.get(
        `/api/inquiries/${this.inquiryId}/tech_pack/production`
      );
    },
    resetQuantity() {
      this.exceededDialog = false;
      this.subceededDialog = false;
      this.getProduction().then((response) => {
        if (response.data.bulkBreakdowns.length !== 0) {
          this.model.bulkColorSizeBreakdown = [];
          for (
            let index = 0;
            index < response.data.bulkBreakdowns.length;
            index++
          ) {
            this.model.bulkColorSizeBreakdown.push(
              response.data.bulkBreakdowns[index]
            );
          }
        }

        if (response.data.sampleBreakdowns.length !== 0) {
          this.model.sampleColorSizeBreakdown = [];
          for (
            let index = 0;
            index < response.data.sampleBreakdowns.length;
            index++
          ) {
            this.model.sampleColorSizeBreakdown.push(
              response.data.sampleBreakdowns[index]
            );
          }
        }
      });
    },
    InputNumbersOnly(evt) {
      /*eslint no-var: "error"*/
      /* eslint no-var: off */
      /* eslint-disable */
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  computed: {
    isInvalidPrice() {

      return parseFloat(this.model.targetMakeCost) && parseFloat(this.model.targetSalesPrice) && parseFloat(this.model.targetMakeCost) >= parseFloat(this.model.targetSalesPrice);
    },
    showBreakdowns() {
      if (
        this.getTheSizeChart &&
        this.getTheSizeChart.sizes &&
        this.getTheSizeChart.sizes.length
      )
        return true;
      return false;
    },
    getTheSizeChart() {
      if (this.model.selectedSizeCategory === 1) {
        return this.womensSizeChart[this.model.sizeChart - 1];
      } else if (this.model.selectedSizeCategory === 2) {
        return this.mensSizeChart[this.model.sizeChart - 1];
      } else if (this.model.selectedSizeCategory === 3) {
        if (this.model.kidsCategory === 1) {
          return this.kidsUkSizeCategory[this.model.sizeChart - 1];
        } else if (this.model.kidsCategory === 2) {
          return this.kidsUsSizeCategory[this.model.sizeChart - 1];
        }
      } else if (
        this.model.selectedSizeCategory === 4 &&
        this.model.otherCategory &&
        this.model.otherSizes.length &&
        this.model.otherSizes[0].size
      ) {
        return {
          sizes: [...this.model.otherSizes.map((item) => item.size)],
        };
      }
      return {};
    },
    sampleQunatityErrors() {
      const errors = [];
      if (!this.$v.model.sampleQunatity.$dirty) return errors;
      !this.$v.model.sampleQunatity.required &&
        errors.push("Sample quantity is required");
      return errors;
    },
    targetSalesPriceErrors() {
      const errors = [];
      if (!this.$v.model.targetSalesPrice.$dirty) return errors;
      !this.$v.model.targetSalesPrice.required &&
        errors.push("Target sales price is required");
      return errors;
    },
    targetMakeCostErrors() {
      const errors = [];
      if (!this.$v.model.targetMakeCost.$dirty) return errors;
      !this.$v.model.targetMakeCost.required &&
        errors.push("Target make cost is required");
      return errors;
    },
    totalQuantityErrors() {
      const errors = [];
      if (!this.$v.model.totalQuantity.$dirty) return errors;
      !this.$v.model.totalQuantity.required &&
        errors.push("Total quantity is required");
      !this.$v.model.totalQuantity.validateQuantity &&
        errors.push("The quantity should be between 50 and 100,000,000");

      return errors;
    },
    bulkDeliveryDateErrors() {
      const errors = [];
      if (!this.$v.model.bulkDeliveryDate.$dirty) return errors;
      !this.$v.model.bulkDeliveryDate.required &&
        errors.push("Expected bulk delivery date is required");
      !this.$v.model.bulkDeliveryDate.validateDate &&
        errors.push("Please select a date after 45 days from requesting date.");
      return errors;
    },
    sizeCategoryErrors() {
      const errors = [];
      if (!this.$v.model.selectedSizeCategory.$dirty) return errors;
      !this.$v.model.selectedSizeCategory.required &&
        errors.push("Size category is required");
      return errors;
    },
    sizeChartErrors() {
      const errors = [];
      if (!this.$v.model.sizeChart.$dirty) return errors;
      !this.$v.model.sizeChart.required &&
        errors.push("Geographical size chart is required");
      return errors;
    },
    otherCategoryErrors() {
      const errors = [];
      if (!this.$v.model.otherCategory.$dirty) return errors;
      !this.$v.model.otherCategory.required &&
        errors.push("Category is required");
      return errors;
    },
    kidsCategoryErrors() {
      const errors = [];
      if (!this.$v.model.kidsCategory.$dirty) return errors;
      !this.$v.model.kidsCategory.required &&
        errors.push("Kids geographical category is required");
      return errors;
    },
    measurementUnitErrors() {
      const errors = [];
      if (!this.$v.model.measurementUnit.$dirty) return errors;
      !this.$v.model.measurementUnit.required &&
        errors.push("Measurement unit is required");
      return errors;
    },
  },

  watch: {
    model: {
      handler: function() {
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    },
    unsupportedFiles: function() {
      if (this.unsupportedFiles.length === 0) {
        this.alert = false;
      }
    },
  },

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;
    this.loading = true;

    this.sampleSelectList();

    this.getInquiry(this.inquiryId).then((response) => {
      const inquiry = response.data.inquiry;
      this.inquiryQty = inquiry.bulk_order_quantity;
      this.model.totalQuantity = inquiry.bulk_order_quantity;
    });

    this.getProduction()
      .then((response) => {
        const inquiryProduction = response.data.InquiryProduction;
        const techPackProduction = response.data.techPackProduction;
        if (inquiryProduction) {
          this.model.selectedSizeCategory = parseInt(
            inquiryProduction.size_category
          );
          this.model.sizeChart = parseInt(
            inquiryProduction.geographical_size_category
          );
          this.model.kidsCategory = parseInt(
            inquiryProduction.kids_geographical_sub_category
          );
          this.model.targetSalesPrice = inquiryProduction.target_sales_price;
          this.model.targetMakeCost = inquiryProduction.target_make_cost;
          this.model.totalQuantity = inquiryProduction.total_quantity;
          this.model.sampleQunatity = inquiryProduction.sample_quantity;
            this.model.otherSizes.pop();
            if (
              !response.data.bulkBreakdowns[0] ||
              !response.data.bulkBreakdowns[0].sizeAndQuantity.length
            ) {
              this.model.totalQuantity = null;
              this.model.selectedSizeCategory = 1;
            } else {
              for (
                let index = 0;
                index < response.data.bulkBreakdowns[0].sizeAndQuantity.length;
                index++
              ) {
                this.model.otherSizes.push({
                  size:
                    response.data.bulkBreakdowns[0].sizeAndQuantity[index].size,
                });
              }
            }
          this.model.otherCategory = inquiryProduction.other_size_category;
        }
        if (techPackProduction) {
          this.model.measurementUnit = techPackProduction.measurement_unit;
          this.model.measurementChartDetail = techPackProduction.measurement_chart_details
          this.model.bulkComments = techPackProduction.bulk_additional_comments
          this.model.sampleComments = techPackProduction.sample_additional_comments
          this.model.bulkDeliveryDate = techPackProduction.delivery_date;
        }
        this.sampleColorSizeBreakdown = response.data.sampleBreakdowns;

        for (
          let index = 0;
          index < response.data.sampleBreakdowns.length;
          index++
        ) {
          this.model.sampleColorSizeBreakdown.push(
            response.data.sampleBreakdowns[index]
          );
        }
        this.oldSampleBreakdown = JSON.parse(
          JSON.stringify(this.model.sampleColorSizeBreakdown)
        );
        for (
          let index = 0;
          index < response.data.bulkBreakdowns.length;
          index++
        ) {
          this.model.bulkColorSizeBreakdown.push(
            response.data.bulkBreakdowns[index]
          );
        }
        this.oldBulkBreakdown = JSON.parse(
          JSON.stringify(this.model.bulkColorSizeBreakdown)
        );
        for (
          let index = 0;
          index < response.data.measurementChartFiles.length;
          index++
        ) {
          this.model.filesMeasurementChart.push({
            file: response.data.measurementChartFiles[index],
          });
        }
        for (
          let index = 0;
          index < response.data.measurementGuideFiles.length;
          index++
        ) {
          this.model.filesSizeMeasuringGuide.push({
            file: response.data.measurementGuideFiles[index],
          });
        }
        for (let index = 0; index < response.data.colorFiles.length; index++) {
          this.model.filesColor.push({
            file: response.data.colorFiles[index],
          });
        }
        this.loading = false;
        this.ignoreFormEdit = true;
        this.$watch(
          "model.selectedSizeCategory",
          this.selectedSizeCategoryWatcher
        );
        this.$watch("model.sizeChart", this.sizeChartWatcher);
        this.$watch("model.kidsCategory", this.kidsCategoryWatcher);
      })
      .catch((error) => {
        this.loading = false;
        if (
          error.response &&
          error.response.status == 400 &&
          error.response.data &&
          error.response.data.error
        ) {
          notification.errors(error.response.data.error);
        } else {
          this.$watch(
            "model.selectedSizeCategory",
            this.selectedSizeCategoryWatcher
          );
          this.$watch("model.sizeChart", this.sizeChartWatcher);
          this.$watch("model.kidsCategory", this.kidsCategoryWatcher);
        }
      });
  },
};
</script>

<style lang="css" scoped>
.required-field {
  color: red;
  font-size: 20px;
}

.contain {
  border: 1px solid #582963;
  height: 150px;
  overflow: hidden;
  resize: both;
  width: 220px;
  border-radius: 5px;
  cursor: pointer;
}

.dropzone:hover {
  border: dashed #c6a8ec;
}

.validationAlert {
  font-size: 12;
  color: #ff5252;
}

.dropzone {
  border-radius: 5px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #37474f;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  width: 120px;
  top: 80%;
  left: 50%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.spinner {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 999;
}

.btn-align {
  display: flex;
  justify-content: flex-end;
}

.download-delete-btn {
  margin-right: 0;
}

.md-table-cell {
  width: 100px !important;
  border-top: none !important;
}

.tbody .md-table-row td {
  border-top: none !important;
}

.md-content {
  border: 1px solid #582963 !important;
  padding: 20px 20px 0px 20px !important;
  border-radius: 5px !important;
}

.md-table-cell >>> .md-table-cell-container {
  padding: 3px 0px 0px 8px !important;
}

.md-table.md-theme-default
  .md-table-row:hover:not(.md-header-row)
  .md-table-cell {
  background: none !important;
}

.dropzone >>> .dz-preview .dz-progress {
  opacity: 0 !important;
}

.dropzone >>> .dz-error-message {
  display: none !important;
}

.dropzone >>> .dz-preview .dz-details {
  background-color: rgb(198, 168, 236, 0.7) !important;
}

.dropzone >>> .dz-preview .dz-remove {
  color: #582963 !important;
}

.section {
  padding: 20px 50px 50px 50px;
}

@media only screen and (max-width: 600px) {
  .btn-align {
    display: grid;
    justify-content: center;
    grid-gap: 10px;
  }

  .section {
    padding: 20px 10px 10px 10px;
  }
}

.bar_color::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.continue_button_color {
  background-color: #492a67 !important;
  border: #492a67 !important;
}

.md-progress-bar {
  height: 25px;
  margin: 5px;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.md-progress-bar.md-theme-default.md-determinate::v-deep .md-progress-bar-fill {
  background-color: #492a67 !important;
}

.md-progress-bar.md-theme-default.md-determinate {
  background-color: #c6a8ec !important;
}
.invalid-input {
  color: red;
  margin-bottom:10px;
}
</style>
