<template>
  <div v-if="loading" class="text-center tech-pack-style">
    <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
  </div>
  <div v-else style="margin-top: 10px 30px 30px 30px;">
    <v-form :readonly="!isEditAccess" class="mt-6" @submit.prevent="submit">
      <div class="grey lighten-5 section">
        <div id="style-progress-indicator">
          <div v-if="showProgress" class="file-uploading-indicator text-center">
            <label>Uploading {{progress}}%</label>
            <md-progress-bar class="color_stroke" md-mode="determinate" :md-value="progress"></md-progress-bar>
          </div>
        </div>
        <h3 class="mt-6 mb-3">Design Information</h3>
        <v-divider></v-divider>
        <v-textarea
          class="mt-4 mb-5"
          outlined
          name="input-7-4"
          v-model="model.inspiration"
          :error-messages="inspirationErrors"
          @input="$v.model.inspiration.$touch()"
          @blur="$v.model.inspiration.$touch()"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          hint="Please describe the design as much as possible. Give links to the products."
        >
          <template slot="label">
            <span>Inspiration or Design Description</span>
            <span class="required-field">*</span>
          </template>
        </v-textarea>

        <v-textarea
          class="mt-4 mb-5"
          outlined
          name="input-7-4"
          label="Web Links to Inspirational Styles"
          v-model="model.links"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          hint="Links to any supporting sketches"
        ></v-textarea>
        <div class="mb-3">Inspiration or Design Sketches</div>
        <div v-if="designSketchesTotalFileSizeError" style="color:red">{{fileSummationErrorMessage}}</div>
        <div v-if="designSketchesFileSizeError" style="color:red">{{maxFileSizeErrorMessage}}</div>
        <vue-dropzone
          v-if="isEditAccess"
          class="dropzone mb-5"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="addDesignSketches"
          @vdropzone-removed-file="removeDesignSketches"
          @vdropzone-error="collectErrors"
        ></vue-dropzone>
        <small
        >Please Add The Inspirations / Sketches / Drawings / Competitor
          Products / and Attach Pictures.</small
        >

        <div v-if="!isEditAccess && !model.filesDesignSketch.length" style="color: red">* No any file has been uploaded !</div>

        <v-row class="mb-10" dense>
          <v-col
            v-for="(file, index) in model.filesDesignSketch"
            :key="index"
            md="6"
            lg="3"
            sm="3"
            xs="6"
          >
            <v-card>
              <v-img
                v-if="
                  file.file.upload_name.split('.')[1] !== 'jpg' &&
                    file.file.upload_name.split('.')[1] !== 'JPG' &&
                    file.file.upload_name.split('.')[1] !== 'png' &&
                    file.file.upload_name.split('.')[1] !== 'jpeg'
                "
                :src="require('../../../assets/folder.png')"
                class="white--text align-end"
                height="150px"
              >
              </v-img>
              <v-img
                v-else
                :src="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                "
                class="white--text align-end"
                height="150px"
              >
              </v-img>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div v-text="file.file.title.substr(0, 20)"></div>
                </v-row
                >
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>


                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn
                  v-if="isEditAccess"
                  fab
                  x-small
                  @click="deleteFile(file.file.upload_name, 'design')"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="grey lighten-5 mt-10 section">
        <h3 class="mt-6 mb-3">Trims Information</h3>
        <v-divider></v-divider>
        <v-textarea
          class="mt-6"
          outlined
          name="input-7-4"
          label="Trims (Button, Thread, etc.)"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          hint='If you want to use special trims if non please specify as "NA"'
          v-model="model.trims"
        ></v-textarea>
        <div class="mb-3">Trim Option Images</div>
        <div v-if="trimImagesTotalFileSizeError" style="color:red">{{fileSummationErrorMessage}}</div>
        <div v-if="trimImagesFileSizeError" style="color:red">{{maxFileSizeErrorMessage}}</div>
        <vue-dropzone
          v-if="isEditAccess"
          class="dropzone mb-5"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="addTrimImages"
          @vdropzone-removed-file="removeTrimImages"
          @vdropzone-error="collectErrors"
        ></vue-dropzone>
        <small>Special Trims Add Those Info With Pictures.</small>

        <div v-if="!isEditAccess && !model.filesTrimImages.length" style="color: red">* No any file has been uploaded !</div>

        <v-row class="mb-10" dense>
          <v-col
            v-for="(file, index) in model.filesTrimImages"
            :key="index"
            md="6"
            lg="3"
            sm="3"
            xs="6"
          >
            <v-card>
              <v-img
                v-if="
                  file.file.upload_name.split('.')[1] !== 'jpg' &&
                    file.file.upload_name.split('.')[1] !== 'JPG' &&
                    file.file.upload_name.split('.')[1] !== 'png' &&
                    file.file.upload_name.split('.')[1] !== 'jpeg'
                "
                :src="require('../../../assets/folder.png')"
                class="white--text align-end"
                height="150px"
              >
              </v-img>
              <v-img
                v-else
                :src="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                "
                class="white--text align-end"
                height="150px"
              >
              </v-img>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div v-text="file.file.title.substr(0, 20)"></div>
                </v-row
                >
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn
                  v-if="isEditAccess"
                  fab
                  x-small
                  @click="deleteFile(file.file.upload_name, 'trims')"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div class="grey lighten-5 mt-10 section">
        <h3 class="mt-6 mb-3">Embellishment Information</h3>
        <v-divider></v-divider>
        <v-textarea
          class="mt-6"
          outlined
          name="input-7-4"
          label="Embellishment (Print, Embroidery, Washing, etc.)"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          hint="Add any relate info on prints, embroidery, washes, dye, etc with pics"
          v-model="model.embellishmentInfo"
        ></v-textarea>
        <div class="mb-3 mt-4">
          Embellishment (Print, Embroidery, Washing, etc.)
        </div>
        <div v-if="embellishmentTotalFileSizeError" style="color:red">{{fileSummationErrorMessage}}</div>
        <div v-if="embellishmentFileSizeError" style="color:red">{{maxFileSizeErrorMessage}}</div>
        <vue-dropzone
          v-if="isEditAccess"
          class="dropzone mb-5"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="addEmbellishments"
          @vdropzone-removed-file="removeEmbellishments"
          @vdropzone-error="collectErrors"
        ></vue-dropzone>
        <small
        >Add Any Relate Info on Prints, Embroidery, Washes, Dye, etc With
          Pics</small
        >

        <div v-if="!isEditAccess && !isEditAccess && !model.filesEmbellishment.length" style="color: red">* No any file has been uploaded !</div>

        <v-row class="mb-10" dense>
          <v-col
            v-for="(file, index) in model.filesEmbellishment"
            :key="index"
            md="6"
            lg="3"
            sm="3"
            xs="6"
          >
            <v-card>
              <v-img
                v-if="
                  file.file.upload_name.split('.')[1] !== 'jpg' &&
                    file.file.upload_name.split('.')[1] !== 'JPG' &&
                    file.file.upload_name.split('.')[1] !== 'png' &&
                    file.file.upload_name.split('.')[1] !== 'jpeg'
                "
                :src="require('../../../assets/folder.png')"
                class="white--text align-end"
                height="150px"
              >
              </v-img>
              <v-img
                v-else
                :src="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                "
                class="white--text align-end"
                height="150px"
              >
              </v-img>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div v-text="file.file.title.substr(0, 20)"></div>
                </v-row
                >
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn
                  v-if="isEditAccess"
                  fab
                  x-small
                  @click="deleteFile(file.file.upload_name, 'embelishment')"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-textarea
          class="mt-6"
          outlined
          name="input-7-4"
          label="Other Information"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          hint="Any additional info and instructions."
          v-model="model.other"
        ></v-textarea>
      </div>

      <div class="grey lighten-5 mt-10 section">
        <h3 class="mt-6 mb-3">Care Instructions</h3>
        <v-divider></v-divider>

        <span>Care Label</span>
        <span class="required-field">*</span>
        <v-radio-group
          v-model="model.careLabel"
          :error-messages="careLabelErrors"
          @input="$v.model.careLabel.$touch()"
          @blur="$v.model.careLabel.$touch()"
        >
          <v-radio
            v-for="(careLabel, index) in careLabelitems"
            :key="index"
            :label="careLabel.name"
            :value="careLabel.id"
            color="purple darken-3"
          >
          </v-radio>
        </v-radio-group>
        <small class="mb-6"
        >Please Select the Care Labels Option for the Collection. If You Want
          to Use Our Care Label Service it Has MOQ of 500 + Per Woven and
          Printed Labels. For More Info Please Contact the Design Team After the
          Completed Style.</small
        >
        <div v-if="model.careLabel == 1">
          <div>
            <span>Main Label</span>
            <span class="required-field">*</span>
            <v-radio-group
              class="mt-3"
              v-model="model.mainLabelOption"
              :error-messages="mainLabelOptionErrors"
              @input="$v.model.mainLabelOption.$touch()"
              @blur="$v.model.mainLabelOption.$touch()"
            >
              <v-radio
                label="Option 1"
                :value="mainLabelItems[0].id"
                color="purple darken-3"
              >
              </v-radio>
              <div class="mb-2 radio">
                <div class="font-weight-medium mx-1">
                  {{ mainLabelItems[0].dimensions }}
                </div>
                <div class="font-weight-medium mx-1">
                  {{ mainLabelItems[0].material + moqMessage }}
                </div>
              </div>

              <div v-viewer style="display: flex" class="mb-3">
                <div class="contain mx-1">
                  <img
                    :src="require('../../../assets/main-label/MAIN LABEL-01.jpeg')"
                    style="object-fit: contain; height: 100%; width: 100%;"
                  />
                </div>
                <div class="contain mx-1">
                  <img
                    :src="require('../../../assets/main-label/MAIN LABEL-02.jpeg')"
                    style="object-fit: contain; height: 100%; width: 100%;"
                  />
                </div>
              </div>
              <v-radio
                label="Option 2"
                :value="mainLabelItems[1].id"
                color="purple darken-3"
              >
              </v-radio>
              <div class="mb-2 radio">
                <div class="font-weight-medium mx-1">
                  {{ mainLabelItems[1].dimensions }}
                </div>
                <div class="font-weight-medium mx-1">
                  {{ mainLabelItems[1].material + moqMessage }}
                </div>
              </div>

              <div v-viewer style="display: flex" class="mb-3">
                <div class="contain mx-1">
                  <img
                    :src="require('../../../assets/main-label/MAIN LABEL-03.jpeg')"
                    style="object-fit: contain; height: 100%; width: 100%;"
                  />
                </div>
                <div class="contain mx-1">
                  <img
                    :src="require('../../../assets/main-label/MAIN LABEL-04.jpeg')"
                    style="object-fit: contain; height: 100%; width: 100%;"
                  />
                </div>
              </div>

              <v-radio
                label="Option 3"
                :value="mainLabelItems[2].id"
                color="purple darken-3"
              >
              </v-radio>
              <div class="mb-2 radio">
                <div class="font-weight-medium mx-1">
                  {{ mainLabelItems[2].dimensions }}
                </div>
                <div class="font-weight-medium mx-1">
                  {{ mainLabelItems[2].material + moqMessage}}
                </div>
              </div>

              <div v-viewer style="display: flex" class="mb-3">
                <div class="contain mx-1">
                  <img
                    :src="require('../../../assets/main-label/MAIN LABEL-05.jpeg')"
                    style="object-fit: contain; height: 100%; width: 100%;"
                  />
                </div>
                <div class="contain mx-1">
                  <img
                    :src="require('../../../assets/main-label/MAIN LABEL-06.jpeg')"
                    style="object-fit: contain; height: 100%; width: 100%;"
                  />
                </div>
              </div>

              <v-radio label="Other" :value="4" color="purple darken-3">
              </v-radio>

              <div class="mx-1" v-if="model.mainLabelOption === 4">
                <vue-dropzone
                  v-if="isEditAccess"
                  class="dropzone mb-5"
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  @vdropzone-file-added="addOtherMainLabel"
                  @vdropzone-removed-file="removeOtherMainLabel"
                  @vdropzone-error="collectErrors"
                ></vue-dropzone>

                <div v-if="otherMainLabelTotalFileSizeError" style="color:red">{{fileSummationErrorMessage}}</div>
                <div v-if="otherMainLabelFileSizeError" style="color:red">{{maxFileSizeErrorMessage}}</div>
                <div v-if="!model.filesOtherMainLabel.length" style="color: red">* No any file has been uploaded !</div>

                <v-row class="mb-10" dense>
                  <v-col
                    v-for="(file, index) in model.filesOtherMainLabel"
                    :key="index"
                    md="6"
                    lg="3"
                    sm="3"
                    xs="6"
                  >
                    <v-card>
                      <v-img
                        v-if="
                          file.file.upload_name.split('.')[1] !== 'jpg' &&
                            file.file.upload_name.split('.')[1] !== 'JPG' &&
                            file.file.upload_name.split('.')[1] !== 'png' &&
                            file.file.upload_name.split('.')[1] !== 'jpeg'
                        "
                        :src="require('../../../assets/folder.png')"
                        class="white--text align-end"
                        height="150px"
                      >
                      </v-img>
                      <v-img
                        v-else
                        :src="
                          `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                        "
                        class="white--text align-end"
                        height="150px"
                      >
                      </v-img>

                      <v-card-text>
                        <v-row align="center" class="mx-0">
                          <div
                            v-text="file.file.title.substr(0, 20)"
                          ></div>
                        </v-row
                        >
                      </v-card-text>
                      <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>

                        <v-btn
                                fab
                                x-small
                                :href="
                          `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                        "
                              >
                                <v-icon>mdi-eye</v-icon>
                        </v-btn>


                        <v-btn
                          fab
                          x-small
                          :href="
                            `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                          "
                        >
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="isEditAccess"
                          fab
                          x-small
                          @click="
                            deleteFile(file.file.upload_name, 'mainLable')
                          "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-radio-group>
          </div>
        </div>

        <div v-if="model.careLabel == 1">
          <span>Care Label</span>
          <span class="required-field">*</span>
          <v-radio-group
            class="mt-3"
            v-model="model.careLabelOption"
            :error-messages="careLabelOptionErrors"
            @input="$v.model.careLabelOption.$touch()"
            @blur="$v.model.careLabelOption.$touch()"
          >
            <v-radio
              label="Option 1"
              :value="careLabelOptionItems[0].id"
              color="purple darken-3"
            >
            </v-radio>
            <div class="mb-2 radio">
              <div class="font-weight-medium mx-1">
                {{ careLabelOptionItems[0].dimensions }}
              </div>
              <div class="font-weight-medium mx-1">
                {{ careLabelOptionItems[0].material + moqMessage}}
              </div>
            </div>

            <div v-viewer style="display: flex" class="mb-3">
              <div class="contain mx-1">
                <img
                  :src="require('../../../assets/care-label/CARE LABEL-01.jpeg')"
                  style="object-fit: contain; height: 100%; width: 100%;"
                />
              </div>
              <div class="contain mx-1">
                <img
                  :src="require('../../../assets/care-label/CARE LABEL-02.jpeg')"
                  style="object-fit: contain; height: 100%; width: 100%;"
                />
              </div>
            </div>
            <v-radio
              label="Option 2"
              :value="careLabelOptionItems[1].id"
              color="purple darken-3"
            >
            </v-radio>
            <div class="mb-2 radio">
              <div class="font-weight-medium mx-1">
                {{ careLabelOptionItems[1].dimensions }}
              </div>
              <div class="font-weight-medium mx-1">
                {{ careLabelOptionItems[1].material + moqMessage}}
              </div>
            </div>

            <div v-viewer style="display: flex" class="mb-3">
              <div class="contain mx-1">
                <img
                  :src="require('../../../assets/care-label/CARE LABEL-03.jpeg')"
                  style="object-fit: contain; height: 100%; width: 100%;"
                />
              </div>
            </div>

            <v-radio
              label="Option 3"
              :value="careLabelOptionItems[2].id"
              color="purple darken-3"
            >
            </v-radio>
            <div class="mb-2 radio">
              <div class="font-weight-medium mx-1">
                {{ careLabelOptionItems[2].dimensions }}
              </div>
              <div class="font-weight-medium mx-1">
                {{ careLabelOptionItems[2].material + moqMessage }}
              </div>
            </div>

            <div v-viewer style="display: flex" class="mb-3">
              <div class="contain mx-1">
                <img
                  :src="require('../../../assets/care-label/CARE LABEL-10.png')"
                  style="object-fit: contain; height: 100%; width: 100%;"
                />
              </div>
            </div>

            <v-radio label="Other" :value="4" color="purple darken-3"> </v-radio>

            <div class="mx-1" v-if="model.careLabelOption === 4">
              <vue-dropzone
                v-if="isEditAccess"
                class="dropzone mb-5"
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                @vdropzone-file-added="addOtherCareLabel"
                @vdropzone-removed-file="removeOtherCareLabel"
                @vdropzone-error="collectErrors"
              ></vue-dropzone>
              <div v-if="otherCareLabelTotalFileSizeError" style="color:red">{{fileSummationErrorMessage}}</div>
              <div v-if="otherCareLabelFileSizeError" style="color:red">{{maxFileSizeErrorMessage}}</div>
              <div v-if="!model.filesOtherCareLabel.length" style="color: red">* No any file has been uploaded !</div>

              <v-row class="mb-10" dense>
                <v-col
                  v-for="(file, index) in model.filesOtherCareLabel"
                  :key="index"
                  md="6"
                  lg="3"
                  sm="3"
                  xs="6"
                >
                  <v-card>
                    <v-img
                      v-if="
                        file.file.upload_name.split('.')[1] !== 'jpg' &&
                          file.file.upload_name.split('.')[1] !== 'JPG' &&
                          file.file.upload_name.split('.')[1] !== 'png' &&
                          file.file.upload_name.split('.')[1] !== 'jpeg'
                      "
                      :src="require('../../../assets/folder.png')"
                      class="white--text align-end"
                      height="150px"
                    >
                    </v-img>
                    <v-img
                      v-else
                      :src="
                        `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                      "
                      class="white--text align-end"
                      height="150px"
                    >
                    </v-img>

                    <v-card-text>
                      <v-row align="center" class="mx-0">
                        <div
                          v-text="file.file.title.substr(0, 20)"
                        ></div>
                      </v-row
                      >
                    </v-card-text>
                    <v-card-actions class="pt-0">
                      <v-spacer></v-spacer>

                      <v-btn
                        fab
                        x-small
                        :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                  "
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn
                        fab
                        x-small
                        :href="
                          `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                        "
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="isEditAccess"
                        fab
                        x-small
                        @click="deleteFile(file.file.upload_name, 'careLabel')"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-radio-group>
        </div>

        <v-textarea
          class="mt-6"
          outlined
          name="input-7-4"
          label="Other - Label and Care Label Dimension"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          v-model="model.otherDimensions"
        ></v-textarea>

        <v-textarea
          class="mt-6"
          outlined
          name="input-7-4"
          label="Additional Information"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          hint="Any additional info and instructions."
          v-model="model.additionalInfo"
        ></v-textarea>
        <div class="mb-3 mt-4">
          If There are Any Other Files to be Uploaded
        </div>
        <div v-if="otherTotalFileSizeError" style="color:red">{{fileSummationErrorMessage}}</div>
        <div v-if="otherFileSizeError" style="color:red">{{maxFileSizeErrorMessage}}</div>
        <vue-dropzone
          v-if="isEditAccess"
          class="dropzone mb-5"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="addOtherFiles"
          @vdropzone-removed-file="removeOtherFiles"
          @vdropzone-error="collectErrors"
        ></vue-dropzone>

        <div v-if="!isEditAccess && !model.filesOther.length" style="color: red">* No any file has been uploaded !</div>

        <v-row class="mb-10" dense>
          <v-col
            v-for="(file, index) in model.filesOther"
            :key="index"
            md="6"
            lg="3"
            sm="3"
            xs="6"
          >
            <v-card>
              <v-img
                v-if="
                  file.file.upload_name.split('.')[1] !== 'jpg' &&
                    file.file.upload_name.split('.')[1] !== 'JPG' &&
                    file.file.upload_name.split('.')[1] !== 'png' &&
                    file.file.upload_name.split('.')[1] !== 'jpeg'
                "
                :src="require('../../../assets/folder.png')"
                class="white--text align-end"
                height="150px"
              >
              </v-img>
              <v-img
                v-else
                :src="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                "
                class="white--text align-end"
                height="150px"
              >
              </v-img>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div v-text="file.file.title.substr(0, 20)"></div>
                </v-row
                >
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn
                  v-if="isEditAccess"
                  fab
                  x-small
                  @click="deleteFile(file.file.upload_name, 'other')"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <div style="display: flex; justify-content: center;">
          <v-alert
            class="mt-5"
            type="error"
            max-width="300px"
            dense
            :value="alert"
            transition="scale-transition"
          >
            Remove unsupported files
          </v-alert>
        </div>
        <div class="btn-align">
          <v-btn color="primary" @click="submit" v-if="isEditAccess">Save</v-btn>

          <v-btn
            class="continue_button_color darken-3"
            @click="isEditAccess ? continueStep() : continueToNext()"
          >Continue
          </v-btn
          >
        </div>
        <v-dialog
          max-width="500"
          v-model="commentDialog"
        >
          <Comment
            @comment-submit="commentSubmit"
            @comment-close="commentDialog = false"
            :page="4"
            serviceType="tech_pack"
          />
        </v-dialog>
      </div>
    </v-form>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
import * as conf from "../../../conf.yml";
import {validationMixin} from "vuelidate";
import {required, requiredIf} from "vuelidate/lib/validators";
import restAdapter from "../../../restAdapter";
import vue2Dropzone from "vue2-dropzone";
import notification from "../../../notification";
import utils from "../../../utils";
import Comment from "../Comment.vue";
import { resetLeaveAlert, setLeaveAlert } from '../../../utils/confirmBox';

export default {
  name: "Style",
  mixins: [validationMixin],
  components: {
    vueDropzone: vue2Dropzone,
    Comment
  },

  props: {
    isOwner: {
      type: Boolean,
    },
    isEditAccess: {
      type: Boolean,
    },
  },

  data() {
    return {
      baseUrl: "",
      unsupportedFiles: [],
      careLabelitems: [
          {
            id: "1",
            name :"Seamless Source Need to Develop"
          },
          {
            id: "2",
            name :"I Will Supply Labels"
          },
        ],
      loading: false,
      commentDialog: false,
      isSubmit: false,
      isContinue: false,
      alert: false,
      moqMessage: " (MOQ-1000pcs)",

      mainLabelItems: [
        {
          id: 1,
          dimensions: "1.5cm * 5cm",
          material: "Woven Label",
          imageOne: null,
          imageTwo: null,
        },
        {
          id: 2,
          dimensions: "1.5cm * 5cm",
          material: "Woven Label",
          imageOne: null,
          imageTwo: null,
        },
        {
          id: 3,
          dimensions: "1.5cm * 7cm",
          material: "Woven Loop Label",
          imageOne: null,
          imageTwo: null,
        },
      ],

      careLabelOptionItems: [
        {
          id: 1,
          dimensions: "2cm * 4.5cm",
          material: "Satin Printed Label",
          imageOne: null,
          imageTwo: null,
        },
        {
          id: 2,
          dimensions: "3cm * 6cm",
          material: "PVC Label",
          imageOne: null,
          imageTwo: null,
        },
        {
          id: 3,
          dimensions: "",
          material: "Heat Transfer",
          imageOne: null,
          imageTwo: null,
        },
      ],

      dropzoneOptions: {
        url: "https://",
        autoProcessQueue: false,
        thumbnailWidth: 200, // px
        thumbnailHeight: 200,
        maxFilesize: 50,
        dictDefaultMessage:
          "<div style='filter: grayscale(1)'>📤</div> Drag and Drop (or) Choose File(s)",
        acceptedFiles: utils.acceptedFileTypes,
        addRemoveLinks: true,
      },

      model: {
        inspiration: "",
        designSketches: [],
        links: "",
        filesDesignSketch: [],
        trimImages: [],
        filesTrimImages: [],
        trims: "",
        embellishmentInfo: "",
        embellishmentfiles: [],
        filesEmbellishment: [],
        other: "",
        otherMainLabel: [],
        filesOtherMainLabel: [],
        careLabel: "",
        otherFiles: [],
        filesOther: [],
        additionalInfo: "",
        mainLabelOption: null,
        careLabelOption: null,
        otherDimensions: "",
        otherCareLabel: [],
        filesOtherCareLabel: [],
      },
      ignoreFormEdit: false,
      designSketchesFileSizeError: false,
      designSketchesTotalFileSizeError:false,
      trimImagesFileSizeError:false,
      trimImagesTotalFileSizeError:false,
      embellishmentFileSizeError:false,
      embellishmentTotalFileSizeError:false,
      otherFileSizeError:false,
      otherTotalFileSizeError:false,
      otherCareLabelTotalFileSizeError:false,
      otherCareLabelFileSizeError:false,
      otherMainLabelTotalFileSizeError :false,
      otherMainLabelFileSizeError:false,
      totalFileSize:102000000,
      maximumFileSize: 52000000,
      fileSummationErrorMessage:"Selected files are exceeding the maximum file summation limit (100mb)",
      maxFileSizeErrorMessage:"One or more files are too large to upload (50mb)",
      showProgress: false,
      progress: 0,
    };
  },

  validations: {
    model: {
      inspiration: {required},
      careLabel: {required},
      mainLabelOption: {
        required: requiredIf(function () {
          if (this.model.careLabel == 1) {
            return true;
          } else {
            return false;
          }
        }),
      },
      careLabelOption: {
        required: requiredIf(function () {
          if (this.model.careLabel == 1) {
            return true;
          } else {
            return false;
          }
        }),
      },
    },
  },

  watch: {
    model: {
      handler: function () {
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    }
  },

  methods: {
    continueToNext() {
      this.$emit("set-done");
    },
    continueStep() {
      this.$v.model.$touch();

      this.$nextTick(() => {
        if (document.querySelector(".v-messages__message")) {
          const domRect = document
            .querySelector(".v-messages__message")
            .getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      });

      if (this.$v.model.$invalid) {
        this.$emit("error");
        return;
      }

      if(
        this.designSketchesFileSizeError ||
        this.designSketchesTotalFileSizeError ||
        this.trimImagesFileSizeError ||
        this.trimImagesTotalFileSizeError ||
        this.embellishmentFileSizeError ||
        this.embellishmentTotalFileSizeError ||
        this.otherFileSizeError ||
        this.otherTotalFileSizeError ||
        this.otherCareLabelTotalFileSizeError ||
        this.otherCareLabelFileSizeError
      ){
        return;
      }
      this.isContinue = true;
      this.submit();
    },
    reset() {
      this.$emit("reset-status", false);
    },

    getStyle() {
      return restAdapter.get(
        `/api/inquiries/${this.inquiryId}/tech_pack/style`
      );
    },

    deleteFile(uniqueId, type) {
      restAdapter.delete("/api/tech_pack/file_delete/" + uniqueId).then(() => {
        notification.success("File deleted successfully");

        if (type === "design") {
          for (
            let index = 0;
            index < this.model.filesDesignSketch.length;
            index++
          ) {
            if (
              this.model.filesDesignSketch[index].file.upload_name == uniqueId
            ) {
              this.model.filesDesignSketch.splice(index, 1);
            }
          }
        } else if (type === "trims") {
          for (
            let index = 0;
            index < this.model.filesTrimImages.length;
            index++
          ) {
            if (
              this.model.filesTrimImages[index].file.upload_name == uniqueId
            ) {
              this.model.filesTrimImages.splice(index, 1);
            }
          }
        } else if (type === "embelishment") {
          for (
            let index = 0;
            index < this.model.filesEmbellishment.length;
            index++
          ) {
            if (
              this.model.filesEmbellishment[index].file.upload_name == uniqueId
            ) {
              this.model.filesEmbellishment.splice(index, 1);
            }
          }
        } else if (type === "mainLable") {
          for (
            let index = 0;
            index < this.model.filesOtherMainLabel.length;
            index++
          ) {
            if (
              this.model.filesOtherMainLabel[index].file.upload_name == uniqueId
            ) {
              this.model.filesOtherMainLabel.splice(index, 1);
            }
          }
        } else if (type === "careLabel") {
          for (
            let index = 0;
            index < this.model.filesOtherCareLabel.length;
            index++
          ) {
            if (
              this.model.filesOtherCareLabel[index].file.upload_name == uniqueId
            ) {
              this.model.filesOtherCareLabel.splice(index, 1);
            }
          }
        } else if (type === "other") {
          for (let index = 0; index < this.model.filesOther.length; index++) {
            if (this.model.filesOther[index].file.upload_name == uniqueId) {
              this.model.filesOther.splice(index, 1);
            }
          }
        }
      });
    },
    collectErrors(file, message, xhr) {
      this.unsupportedFiles.push({file});
    },
    addDesignSketches: function (file) {
      if(file.size > this.maximumFileSize){
        this.designSketchesFileSizeError = true;
      }
      this.model.designSketches.push({file});

      let fileSize = 0;
      for (let index = 0; index < this.model.designSketches.length; index++) {
        fileSize = fileSize + this.model.designSketches[index].file.size;
      }
      this.designSketchesTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
    },
    removeDesignSketches(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.designSketches.length; index++) {
        if (this.model.designSketches[index].file.name === file.name) {
          this.model.designSketches.splice(index, 1);
        }
        fileSize = fileSize + (this.model.designSketches[index] ? this.model.designSketches[index].file.size : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.designSketchesTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
      this.designSketchesFileSizeError = this.unsupportedFiles.length > 0 ? true : false;
    },
    addTrimImages: function (file) {

      if(file.size > this.maximumFileSize){
        this.trimImagesFileSizeError = true;
      }

      this.model.trimImages.push({file});

      let fileSize = 0;
      for (let index = 0; index < this.model.trimImages.length; index++) {
        fileSize = fileSize + this.model.trimImages[index].file.size;
      }
      this.trimImagesTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
    },

    removeTrimImages(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.trimImages.length; index++) {
        if (this.model.trimImages[index].file.name === file.name) {
          this.model.trimImages.splice(index, 1);
        }
        fileSize = fileSize + (this.model.trimImages[index] ? this.model.trimImages[index].file.size : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.trimImagesTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
      this.trimImagesFileSizeError = this.unsupportedFiles.length > 0 ? true : false;
    },
    addEmbellishments: function (file) {

      if(file.size > this.maximumFileSize){
        this.embellishmentFileSizeError = true;
      }

      this.model.embellishmentfiles.push({file});

      let fileSize = 0;
      for (let index = 0; index < this.model.embellishmentfiles.length; index++) {
        fileSize = fileSize + this.model.embellishmentfiles[index].file.size;
      }
      this.embellishmentTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
    },

    removeEmbellishments(file) {
      let fileSize = 0;
      for (
        let index = 0;
        index < this.model.embellishmentfiles.length;
        index++
      ) {
        if (this.model.embellishmentfiles[index].file.name === file.name) {
          this.model.embellishmentfiles.splice(index, 1);
        }
        fileSize = fileSize + (this.model.embellishmentfiles[index] ? this.model.embellishmentfiles[index].file.size : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.embellishmentTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
      this.embellishmentFileSizeError = this.unsupportedFiles.length > 0 ? true : false;
    },
    addEmbellishmentImages: function (file) {
      this.model.embellishmentImages.push({file});
    },
    removeEmbellishmentsImages(file) {
      for (
        let index = 0;
        index < this.model.embellishmentImages.length;
        index++
      ) {
        if (this.model.embellishmentImages[index].file.name === file.name) {
          this.model.embellishmentImages.splice(index, 1);
        }
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
    },
    addOtherFiles: function (file) {

      if(file.size > this.maximumFileSize){
        this.otherFileSizeError = true;
      }

      this.model.otherFiles.push({file});

      let fileSize = 0;
      for (let index = 0; index < this.model.other.length; index++) {
        fileSize = fileSize + this.model.other[index].file.size;
      }
      this.otherTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
    },
    removeOtherFiles(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.otherFiles.length; index++) {
        if (this.model.otherFiles[index].file.name === file.name) {
          this.model.otherFiles.splice(index, 1);
        }
        fileSize = fileSize + (this.model.otherFiles[index] ? this.model.otherFiles[index].file.size : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.otherTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
      this.otherFileSizeError = this.unsupportedFiles.length > 0 ? true : false;
    },
    addOtherMainLabel: function (file) {

      if(file.size > this.maximumFileSize){
        this.otherMainLabelFileSizeError = true;
      }

      this.model.otherMainLabel.push({file});

      let fileSize = 0;
      for (let index = 0; index < this.model.otherMainLabel.length; index++) {
        fileSize = fileSize + this.model.otherMainLabel[index].file.size;
      }
      this.otherMainLabelTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
    },
    removeOtherMainLabel(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.otherMainLabel.length; index++) {
        if (this.model.otherMainLabel[index].file.name === file.name) {
          this.model.otherMainLabel.splice(index, 1);
        }
        fileSize = fileSize + (this.model.otherMainLabel[index] ? this.model.otherMainLabel[index].file.size : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.otherMainLabelTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
      this.otherMainLabelFileSizeError = this.unsupportedFiles.length > 0 ? true : false;
    },
    addOtherCareLabel: function (file) {

      if(file.size > this.maximumFileSize){
        this.otherCareLabelFileSizeError = true;
      }

      this.model.otherCareLabel.push({file});

      let fileSize = 0;
      for (let index = 0; index < this.model.otherCareLabel.length; index++) {
        fileSize = fileSize + this.model.otherCareLabel[index].file.size;
      }
      this.otherCareLabelTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
    },

    removeOtherCareLabel(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.otherCareLabel.length; index++) {
        if (this.model.otherCareLabel[index].file.name === file.name) {
          this.model.otherCareLabel.splice(index, 1);
        }
        fileSize = fileSize + (this.model.otherCareLabel[index] ? this.model.otherCareLabel[index].file.size : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.otherCareLabelTotalFileSizeError = fileSize > this.totalFileSize ? true : false;
      this.otherCareLabelFileSizeError = this.unsupportedFiles.length > 0 ? true : false;
    },
    getFabric() {
      return restAdapter.get(`/api/inquiries/${this.inquiryId}/tech_pack/style`);
    },
    commentSubmit() {
      this.commentDialog = false;
      this.isSubmit = true;
      this.submit();
      this.continueNextPage();
    },
    continueNextPage() {
      if(this.isContinue){
        this.isContinue = false;
        this.$emit('set-done');
      }
    },
    addComment() {
      if (!this.isOwner) {
        this.commentDialog = true;
      } else {
        this.commentSubmit();
      }
    },
    submit() {
      this.$v.model.$touch();
      if(!this.isSubmit && this.$store.state.routeLeaveAlert){
        this.addComment();
        return;
      }
      this.isSubmit = false;
      this.$nextTick(() => {
        if (document.querySelector(".v-messages__message")) {
          const domRect = document
            .querySelector(".v-messages__message")
            .getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      });

      if (this.$v.model.$invalid) {
        return;
      }

      if(
        this.designSketchesFileSizeError ||
        this.designSketchesTotalFileSizeError ||
        this.trimImagesFileSizeError ||
        this.trimImagesTotalFileSizeError ||
        this.embellishmentFileSizeError ||
        this.embellishmentTotalFileSizeError ||
        this.otherFileSizeError ||
        this.otherTotalFileSizeError ||
        this.otherCareLabelTotalFileSizeError ||
        this.otherCareLabelFileSizeError
      ){
        return;
      }

      if (this.unsupportedFiles.length > 0) {
        this.alert = true;
        return;
      }
      const formData = new FormData();

      for (let index = 0; index < this.model.designSketches.length; index++) {
        formData.append(
          "design_files[]",
          this.model.designSketches[index].file
        );
      }
      for (let index = 0; index < this.model.trimImages.length; index++) {
        formData.append("trim_files[]", this.model.trimImages[index].file);
      }
      for (
        let index = 0;
        index < this.model.embellishmentfiles.length;
        index++
      ) {
        formData.append(
          "embellishment_files[]",
          this.model.embellishmentfiles[index].file
        );
      }

      for (let index = 0; index < this.model.otherMainLabel.length; index++) {
        formData.append(
          "main_label_files[]",
          this.model.otherMainLabel[index].file
        );
      }

      for (let index = 0; index < this.model.otherCareLabel.length; index++) {
        formData.append(
          "care_label_files[]",
          this.model.otherCareLabel[index].file
        );
      }

      for (let index = 0; index < this.model.otherFiles.length; index++) {
        formData.append(
          "other_label_files[]",
          this.model.otherFiles[index].file
        );
      }

      formData.append("design_description", this.model.inspiration);

      formData.append("inspirational_styles", this.model.links);

      formData.append("trims", this.model.trims);

      formData.append("care_label", this.model.careLabel);

      formData.append("additional_information", this.model.additionalInfo);

      formData.append("care_label_option", this.model.careLabelOption);

      formData.append("main_label_option", this.model.mainLabelOption);

      formData.append("other_label", this.model.otherDimensions);

      formData.append("embellishment_other_information", this.model.other);

      formData.append("embellishment", this.model.embellishmentInfo);

      this.showProgress = true;
      document.getElementById('style-progress-indicator').scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });

      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/tech_pack/style`, formData,
          {
            onUploadProgress: progressEvent => {
              this.progress = (Math.floor((progressEvent.loaded * 100) / progressEvent.total))
            }
          }
        )
        .then((response) => {
          this.showProgress = false;
          this.reset();
          notification.success("Style Details Saved Successfully");
        })
        .catch((error) => {
          this.showProgress = false;
          this.loading = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
      resetLeaveAlert();
      this.ignoreFormEdit = true;
      this.$v.model.$reset();
      this.continueNextPage();
    },
  },

  computed: {
    careLabelErrors() {
      const errors = [];
      if (!this.$v.model.careLabel.$dirty) return errors;
      !this.$v.model.careLabel.required &&
      errors.push("Care label is required");
      return errors;
    },
    inspirationErrors() {
      const errors = [];
      if (!this.$v.model.inspiration.$dirty) return errors;
      !this.$v.model.inspiration.required &&
      errors.push("Inspiration is required");
      return errors;
    },
    mainLabelOptionErrors() {
      const errors = [];
      if (!this.$v.model.mainLabelOption.$dirty) return errors;
      !this.$v.model.mainLabelOption.required &&
      errors.push("Main label is required");
      return errors;
    },
    careLabelOptionErrors() {
      const errors = [];
      if (!this.$v.model.careLabelOption.$dirty) return errors;
      !this.$v.model.careLabelOption.required &&
      errors.push("Care label is required");
      return errors;
    },
  },

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;

    this.loading = true;

    this.getStyle().then((response) => {
      const style = response.data.style;

      if (style) {
        this.model.inspiration = style.design_description;
        this.model.links = style.inspirational_styles;
        this.model.trims = style.trims;
        this.model.careLabel = style.care_label;
        this.model.otherDimensions = style.other_label;
        this.model.embellishmentInfo = style.embellishment;
        this.model.other = style.embellishment_other_information;
        this.model.additionalInfo = style.additional_information;
        this.model.careLabelOption = parseInt(style.care_label_option);
        this.model.mainLabelOption = parseInt(style.main_label_option);

        for (let index = 0; index < response.data.design_files.length; index++) {
          this.model.filesDesignSketch.push({
            file: response.data.design_files[index],
          });
        }

        for (
          let index = 0;
          index < response.data.embellishment_files.length;
          index++
        ) {
          this.model.filesEmbellishment.push({
            file: response.data.embellishment_files[index],
          });
        }

        for (
          let index = 0;
          index < response.data.other_label_files.length;
          index++
        ) {
          this.model.filesOther.push({
            file: response.data.other_label_files[index],
          });
        }

        for (let index = 0; index < response.data.trim_files.length; index++) {
          this.model.filesTrimImages.push({
            file: response.data.trim_files[index],
          });
        }

        for (
          let index = 0;
          index < response.data.main_label_files.length;
          index++
        ) {
          this.model.filesOtherMainLabel.push({
            file: response.data.main_label_files[index],
          });
        }

        for (
          let index = 0;
          index < response.data.care_label_files.length;
          index++
        ) {
          this.model.filesOtherCareLabel.push({
            file: response.data.care_label_files[index],
          });
        }
      }
      this.ignoreFormEdit = true;
      this.loading = false;
    });

    this.loading = false;
  },
};
</script>

<style lang="css" scoped>
.tech-pack-style {
  height: 500px;
}

.required-field {
  color: red;
  font-size: 20px;
}

.btn-align {
  display: flex;
  justify-content: flex-end;
}

.dropzone:hover {
  border: dashed #c6a8ec;
}

.dropzone {
  border-radius: 5px;
}

.contain {
  border: 1px solid #582963;
  height: 150px;
  overflow: hidden;
  resize: both;
  width: 220px;
  border-radius: 5px;
  cursor: pointer;
}

.dropzone >>> .dz-preview .dz-progress {
  opacity: 0 !important;
}

.dropzone >>> .dz-error-message {
  display: none !important;
}

.dropzone >>> .dz-preview .dz-details{
  background-color: rgb(198, 168, 236, .7) !important;
}

.dropzone >>> .dz-preview .dz-remove{
  color: #582963 !important;
}

.section {
  padding: 20px 50px 50px 50px;
  text-align: left;
}

.radio {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .btn-align {
    display: flex;
    justify-content: center;
  }

  .section {
    padding: 20px 10px 10px 10px;
  }

  .radio {
    display: grid;
  }
}

.color_stroke::v-deep .md-progress-spinner-circle{
    stroke: #492a67 !important;
}

.continue_button_color{
  background-color: #492a67 !important;
  border: #492a67 !important;
}

.md-progress-bar {
  height: 25px;
  margin: 5px;
}

.md-progress-bar.md-theme-default.md-determinate::v-deep .md-progress-bar-fill{
  background-color: #492a67 !important;
}

.md-progress-bar.md-theme-default.md-determinate{
  background-color: #c6a8ec !important;
}

</style>
