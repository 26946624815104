<template>
  <div v-if="loading" class="text-center tech-pack">
    <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
  </div>
  <div v-else>
    <div class="grey lighten-5 section">
      <v-form :readonly="!isEditAccess" @submit.prevent="submit">
        <v-text-field
          label="Style No"
          v-model="model.styleNo"
          required
          disabled
          outlined
          dense
          color="purple darken-3"
        ></v-text-field>
        <v-text-field
          label="Buyer / Business Name"
          v-model="model.brandName"
          required
          disabled
          outlined
          dense
          color="purple darken-3"
        ></v-text-field>
        <v-text-field
          label="Designer First Name"
          v-model="model.designerFirstName"
          outlined
          dense
          disabled
          color="purple darken-3"
        ></v-text-field>
        <v-text-field
          label="Designer Last Name"
          v-model="model.designerLastName"
          disabled
          outlined
          dense
          color="purple darken-3"
        ></v-text-field>
        <md-field
          class="md_field_margin"
          :class="getValidationClass('category')"
          id="category"
        >
          <label for="category">Category</label>
          <md-select
            name="category"
            v-model="model.category"
          >
            <md-option
              class="list_color"
              v-for="category in categories"
              :key="'category-' + category.id"
              :value="category.id"
              >{{ category.name }}</md-option
            >
          </md-select>
          <span
            class="md-error error_msg_color"
            v-if="categoryValidationError"
            >{{ categoryValidationError }}</span
          >
        </md-field>
        <v-text-field
          label="Other Category"
          v-model="model.otherCategory"
          outlined
          dense
          color="purple darken-3"
          maxLength="500"
        ></v-text-field>

        <v-text-field
          label="Style Name"
          v-model="model.styleName"
          :error-messages="styleNameErrors"
          outlined
          dense
          hint="eg: T-shirt, Maxi dress etc..."
          color="purple darken-3"
          maxLength="500"
        ></v-text-field>
        <div class="btn-align">
          <v-btn color="primary" v-if="isEditAccess" @click="submit">
            Save
          </v-btn>

          <v-btn
            class="continue_button_color"
            @click="isEditAccess ? continueStep() : continueToNext()"
          >Continue
          </v-btn
          >
        </div>
      </v-form>
    </div>
    <v-dialog
      max-width="500"
      v-model="commentDialog"
    >
    <Comment
      @comment-submit="commentSubmit"
      @comment-close="commentDialog = false"
      :page="1"
      serviceType="tech_pack"
    />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
import * as conf from "../../../conf.yml";
import validationMixins from "../../../validation/validation_mixin";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import restAdapter from "../../../restAdapter";
import notification from "../../../notification";
import Comment from "../Comment.vue";
import { resetLeaveAlert, setLeaveAlert } from '../../../utils/confirmBox';
import routes from '../../../router/routes';

export default {
  name: "Techpack",
  mixins: [validationMixin,validationMixins],
  components: {Comment},

  data() {
    return {
      baseUrl: "",
      inquiryId: "",
      categories: [],
      loading: false,
      commentDialog: false,
      isSubmit: false,
      isContinue: false,

      model: {
        styleName: "",
        designerFirstName: "",
        designerLastName: "",
        styleNo: "",
        category: null,
        brandName: "",
        otherCategory: "",
      },
      ignoreFormEdit: false
    };
  },

  validations: {
    model: {
      styleName: {required},
      category: {required},
    },
  },

  props: {
    isOwner: {
      type: Boolean,
    },
    isEditAccess: {
      type: Boolean,
    },
  },

  watch: {
    model: {
      handler: function () {
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    }
  },

  methods: {
    continueToNext() {
      this.$emit("set-done");
    },
    continueStep() {
      this.$v.model.$touch();

      if (this.$v.model.$invalid) {
        this.$emit("error");
        return;
      }
      this.isContinue = true;
      this.submit();
    },
    reset() {
      this.$emit("reset-status", false);
    },
    getTechPack() {
      return restAdapter.get(`/api/inquiries/${this.inquiryId}/tech_pack`);
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    getCategories() {
      return restAdapter.get("/api/inquire_categories");
    },
    getCategoryName(id) {
      const category = this.categories.find((category) => category.id == id);
      this.model.category = category.id;
    },
    commentSubmit() {
      this.commentDialog = false;
      this.isSubmit = true;
      this.submit();
      this.continueNextPage();
    },
    continueNextPage() {
      if(this.isContinue){
        this.isContinue = false;
        this.$emit('set-done');
      }
    },
    addComment() {
      if (!this.isOwner) {
        this.commentDialog = true;
      } else {
        this.commentSubmit();
      }
    },
    submit() {
      this.$v.model.$touch();
      if (this.$v.model.$invalid) {
        return;
      }
      if(!this.isSubmit && this.$store.state.routeLeaveAlert){
        this.addComment();
        return;
      }
      this.isSubmit = false;
      const data = {
        style_name: this.model.styleName,
        category: this.model.category,
        other_category_details: this.model.otherCategory,
      };

      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/tech_pack`, data)
        .then((response) => {
          this.reset();
          notification.success("Tech Pack Details Saved Successfully");
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
      resetLeaveAlert();
      this.ignoreFormEdit = true;
      this.$v.model.$reset();
      this.continueNextPage();
    },
  },

  computed: {
    styleNameErrors() {
      const errors = [];
      if (!this.$v.model.styleName.$dirty) return errors;
      !this.$v.model.styleName.required &&
      errors.push("Style name is required");
      return errors;
    },
    categoryValidationError() {
      if (!this.$v.model.category.required) {
        return "The category is required";
      }
      return null;
    },
  },

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;

    this.loading = true;
    this.getCategories().then((response) => {
      this.categories = response.data.categories;
      this.getInquiry(this.inquiryId).then((response) => {
        const inquiry = response.data.inquiry;
        this.model.styleNo = inquiry.style_number;
        this.model.styleName = inquiry.style_name;
        this.model.brandName = inquiry.customer.brand_name;
        this.model.designerFirstName = inquiry.customer.first_name;
        this.model.designerLastName = inquiry.customer.last_name;
        this.createdBy = inquiry.created_by;
        this.getCategoryName(parseInt(inquiry.categories));
        this.ignoreFormEdit = true;
        resetLeaveAlert();
      });
    });

    this.getTechPack().then((response) => {
      const techPack = response.data.techPack;
      if (techPack) {
        this.model.otherCategory = techPack.other_category_details;
        this.ignoreFormEdit = true;
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        notification.error("You don't have Access");
        this.$router.push({name: routes.COLLECTION_LIST});
      }
    });
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.tech-pack {
  height: 500px;
}

.tech-pack-align {
  margin-top: 10px 30px 30px 30px;
}

.btn-align {
  display: flex;
  justify-content: flex-end;
}

.section {
  padding: 20px 50px 50px 50px;
}

@media only screen and (max-width: 600px) {
  .btn-align {
    display: flex;
    justify-content: center;
  }

  .section {
    padding: 20px 10px 10px 10px;
  }
}
.list_color.md-selected::v-deep .md-list-item-text{
    color: #fa735f !important;
}
.color_stroke::v-deep .md-progress-spinner-circle{
    stroke: #492a67 !important;
}
.continue_button_color{
  background-color: #492a67 !important;
  border: #492a67 !important;
}
.md_field_margin{
  margin-top: -15px;
}
.error_msg_color{
  color : #ff5252 !important;
}
</style>
