<template>
  <div v-if="loading" class="text-center tech-pack-hangtag">
    <md-progress-spinner
      class="color_stroke"
      md-mode="indeterminate"
    ></md-progress-spinner>
  </div>

  <div v-else>
    <div v-if="submitting" class="text-center">
      <md-progress-spinner
        class="color_stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <v-form :readonly="!isEditAccess" class="mt-6" @submit.prevent="submit">
      <div class="grey lighten-5 section">
        <div id="hangtag-progress-indicator">
          <div v-if="showProgress" class="file-uploading-indicator text-center">
            <label>Uploading {{ progress }}%</label>
            <md-progress-bar
              class="color_stroke"
              md-mode="determinate"
              :md-value="progress"
            ></md-progress-bar>
          </div>
        </div>
        <h2 class="mb-2">Hangtag and Packaging</h2>
        <v-divider></v-divider>
        <h3 class="mt-5 mb-2">Hangtag</h3>
        <v-divider></v-divider>
        <div>
          <div class="mt-6">Hangtag Dimensions Offered</div>
          <v-radio-group class="mt-3" v-model="model.hangTagOption">
            <v-radio
              label="Option 1"
              :value="hangTagItems[0].id"
              color="purple darken-3"
            >
            </v-radio>
            <div class="mb-2 radio-value">
              <div class="font-weight-medium mx-1">
                {{ hangTagItems[0].dimensions }}
              </div>
              <div class="font-weight-medium mx-1">
                {{ hangTagItems[0].material + moqMessage }}
              </div>
            </div>

            <div v-viewer class="mb-3 radio-value">
              <div class="contain mx-1">
                <img
                  :src="require('../../../assets/hang-tag/hang-tag1.jpeg')"
                  style="object-fit: contain; height: 100%; width: 100%;"
                />
              </div>
            </div>
            <v-radio
              label="Option 2"
              :value="hangTagItems[1].id"
              color="purple darken-3"
            >
            </v-radio>
            <div class="mb-2 radio-value">
              <div class="font-weight-medium mx-1">
                {{ hangTagItems[1].dimensions }}
              </div>
              <div class="font-weight-medium mx-1">
                {{ hangTagItems[1].material + moqMessage }}
              </div>
            </div>

            <div v-viewer class="mb-3 radio-value">
              <div class="contain mx-1">
                <img
                  :src="require('../../../assets/hang-tag/hang-tag2.jpeg')"
                  style="object-fit: contain; height: 100%; width: 100%;"
                />
              </div>
            </div>

            <v-radio
              label="Option 3"
              :value="hangTagItems[2].id"
              color="purple darken-3"
            >
            </v-radio>
            <div class="mb-2 radio-value">
              <div class="font-weight-medium mx-1">
                {{ hangTagItems[2].dimensions }}
              </div>
              <div class="font-weight-medium mx-1">
                {{ hangTagItems[2].material + moqMessage }}
              </div>
            </div>

            <div v-viewer class="mb-3 radio-value">
              <div class="contain mx-1">
                <img
                  :src="require('../../../assets/hang-tag/hang-tag3.jpeg')"
                  style="object-fit: contain; height: 100%; width: 100%;"
                />
              </div>
            </div>

            <v-radio
              label="Option 4"
              :value="hangTagItems[3].id"
              color="purple darken-3"
            >
            </v-radio>
            <div class="mb-2 radio-value">
              <div class="font-weight-medium mx-1">
                {{ hangTagItems[3].dimensions }}
              </div>
              <div class="font-weight-medium mx-1">
                {{ hangTagItems[3].material + moqMessage }}
              </div>
            </div>

            <div v-viewer class="mb-3 radio-value">
              <div class="contain mx-1">
                <img
                  :src="require('../../../assets/hang-tag/hang-tag4.jpeg')"
                  style="object-fit: contain; height: 100%; width: 100%;"
                />
              </div>
            </div>

            <v-radio
              label="Option 5"
              :value="hangTagItems[4].id"
              color="purple darken-3"
            >
            </v-radio>
            <div class="mb-2 radio-value">
              <div class="font-weight-medium mx-1">
                {{ hangTagItems[4].dimensions }}
              </div>
              <div class="font-weight-medium mx-1">
                {{ hangTagItems[4].material + moqMessage }}
              </div>
            </div>

            <div v-viewer class="mb-3 radio-value">
              <div class="contain mx-1">
                <img
                  :src="require('../../../assets/hang-tag/hang-tag5.jpeg')"
                  style="object-fit: contain; height: 100%; width: 100%;"
                />
              </div>
            </div>

            <v-radio
              label="Other"
              :value="hangTagItems[5].id"
              color="purple darken-3"
            >
            </v-radio>

            <div class="mx-1" v-if="model.mainLabelOption === 4">
              <vue-dropzone
                v-if="isEditAccess"
                class="dropzone mb-5"
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                @vdropzone-file-added="addOtherMainLabel"
                @vdropzone-removed-file="removeOtherMainLabel"
                @vdropzone-error="collectErrors"
              ></vue-dropzone>

              <v-row class="mb-10" dense>
                <v-col
                  v-for="(file, index) in model.filesOtherMainLabel"
                  :key="index"
                  md="6"
                  lg="3"
                  sm="3"
                  xs="6"
                >
                  <v-card>
                    <v-img
                      v-if="
                        file.file.upload_name.split('.')[1] !== 'jpg' &&
                          file.file.upload_name.split('.')[1] !== 'JPG' &&
                          file.file.upload_name.split('.')[1] !== 'png' &&
                          file.file.upload_name.split('.')[1] !== 'jpeg'
                      "
                      :src="require('../../../assets/folder.png')"
                      class="white--text align-end"
                      height="150px"
                    >
                    </v-img>
                    <v-img
                      v-else
                      :src="
                        `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                      "
                      class="white--text align-end"
                      height="150px"
                    >
                    </v-img>

                    <v-card-text>
                      <v-row align="center" class="mx-0">
                        <div v-text="file.file.title.substr(0, 16)"></div>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                      <v-spacer></v-spacer>

                      <v-btn
                        fab
                        x-small
                        :href="
                          `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                        "
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="isEditAccess"
                        fab
                        x-small
                        @click="deleteFile(file.file.upload_name, 'mainLable')"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-radio-group>
        </div>

        <v-textarea
          class="mt-4 mb-5"
          outlined
          name="input-7-4"
          label="Other Hangtag Dimensions"
          v-model="model.hangTagDimensions"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          hint="Please mention the GSM, size, colour needed for the hangtag"
        ></v-textarea>
        <div class="mb-3">Hangtag Art Work</div>
        <div v-if="hangTagImagesTotalFileSizeError" style="color:red">
          {{ fileSummationErrorMessage }}
        </div>
        <div v-if="hangTagImagesFileSizeError" style="color:red">
          {{ maxFileSizeErrorMessage }}
        </div>
        <vue-dropzone
          v-if="isEditAccess"
          class="dropzone mb-5"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="addhangTagImages"
          @vdropzone-removed-file="removehangTagImages"
          @vdropzone-error="collectErrors"
        ></vue-dropzone>

        <div
          v-if="!isEditAccess && !model.filesHangTag.length"
          style="color: red"
        >
          * No any file has been uploaded !
        </div>

        <v-row class="mb-10" dense>
          <v-col
            v-for="(file, index) in model.filesHangTag"
            :key="index"
            md="6"
            lg="3"
            sm="3"
            xs="6"
          >
            <v-card>
              <v-img
                v-if="
                  file.file.upload_name.split('.')[1] !== 'jpg' &&
                    file.file.upload_name.split('.')[1] !== 'JPG' &&
                    file.file.upload_name.split('.')[1] !== 'png' &&
                    file.file.upload_name.split('.')[1] !== 'jpeg'
                "
                :src="require('../../../assets/folder.png')"
                class="white--text align-end"
                height="150px"
              >
              </v-img>
              <v-img
                v-else
                :src="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                "
                class="white--text align-end"
                height="150px"
              >
              </v-img>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div v-text="file.file.title.substr(0, 16)"></div>
                </v-row>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn fab x-small @click="deleteFile(file.file.upload_name)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="grey lighten-5 mt-10 section">
        <h3 class="mt-6 mb-3">Packaging</h3>
        <v-divider></v-divider>
        <v-textarea
          class="mt-6"
          outlined
          label="Method of Folding"
          clearable
          clear-icon="mdi-close-circle"
          color="purple darken-3"
          hint='If you want to use special trims if non please specify as "NA"'
          v-model="model.foldingMethod"
        ></v-textarea>
        <div class="mt-5">Packaging</div>
        <v-radio-group v-model="model.packaging">
          <v-radio
            v-for="(packaging, index) in packagingItem"
            :key="index"
            :label="packaging"
            :value="packaging"
            @change="clearOtherPackaging"
            color="purple darken-3"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          v-if="model.packaging === 'Other'"
          label="Other"
          style="width: 300px;"
          v-model="model.otherPackaging"
          outlined
          dense
          color="purple darken-3"
          maxLength="200"
        ></v-text-field>
        <div class="mb-3">Packaging Images</div>
        <div v-if="packagingImagesTotalFileSizeError" style="color:red">
          {{ fileSummationErrorMessage }}
        </div>
        <div v-if="packagingImagesFileSizeError" style="color:red">
          {{ maxFileSizeErrorMessage }}
        </div>
        <vue-dropzone
          v-if="isEditAccess"
          class="dropzone"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="addpackagingImages"
          @vdropzone-removed-file="removepackagingImages"
          @vdropzone-error="collectErrors"
        ></vue-dropzone>
        <small>
          Please Upload the Artwork or Any Image Of the Packing Method You
          Prefer
        </small>

        <div
          v-if="!isEditAccess && !model.filespackagingImages.length"
          style="color: red"
        >
          * No any file has been uploaded !
        </div>

        <v-row class="mb-10" dense>
          <v-col
            v-for="(file, index) in model.filespackagingImages"
            :key="index"
            md="6"
            lg="3"
            sm="3"
            xs="6"
          >
            <v-card>
              <v-img
                v-if="
                  file.file.upload_name.split('.')[1] !== 'jpg' &&
                    file.file.upload_name.split('.')[1] !== 'JPG' &&
                    file.file.upload_name.split('.')[1] !== 'png' &&
                    file.file.upload_name.split('.')[1] !== 'jpeg'
                "
                :src="require('../../../assets/folder.png')"
                class="white--text align-end"
                height="150px"
              >
              </v-img>
              <v-img
                v-else
                :src="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                "
                class="white--text align-end"
                height="150px"
              >
              </v-img>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div v-text="file.file.title.substr(0, 16)"></div>
                </v-row>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_download/${file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn
                  v-if="isEditAccess"
                  fab
                  x-small
                  @click="deleteFilePackaging(file.file.upload_name)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <div v-if="isOwner && isSubmitted === 0">
          <div class="mb-2">Terms and Conditions</div>
          <ul>
            <li>
              I ensure above details are correct according to my knowledge and I
              have gone through it before submit.
            </li>
            <li>
              If I have given wrong information, I am taking the full
              responsibility and pay any additional costs if applicable, to edit
              the tech pack.
            </li>
            <li>
              If I want to change my designs after I submit the tech pack
              development request I am responsible to pay any additional costs
              if applicable, to make a new tech pack.
            </li>
          </ul>
          <v-checkbox
            v-model="model.termsAndCondition"
            :error-messages="checkboxErrors"
            label="I accept the Terms and Conditions."
            required
            @change="$v.model.termsAndCondition.$touch()"
            @blur="$v.model.termsAndCondition.$touch()"
          ></v-checkbox>
        </div>
        <div style="display: flex; justify-content: center;">
          <v-alert
            class="mt-5"
            type="error"
            max-width="300px"
            dense
            :value="alert"
            transition="scale-transition"
          >
            Remove Unsupported Files
          </v-alert>
        </div>
        <div class="btn-align">
          <v-btn color="primary" @click="submit" v-if="isEditAccess"
            >Save</v-btn
          >

          <v-btn
            class="continue_button_color darken-3"
            v-if="isOwner && isSubmitted === 0"
            @click="submitTechPack"
            >Submit
          </v-btn>
        </div>
        <v-dialog max-width="500" v-model="commentDialog">
          <Comment
            @comment-submit="commentSubmit"
            @comment-close="commentDialog = false"
            :page="5"
            serviceType="tech_pack"
          />
        </v-dialog>
      </div>
    </v-form>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
import * as conf from "../../../conf.yml";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import restAdapter from "../../../restAdapter";
import vue2Dropzone from "vue2-dropzone";
import notification from "../../../notification";
import utils from "../../../utils";
import Comment from "../Comment.vue";
import { resetLeaveAlert, setLeaveAlert } from "../../../utils/confirmBox";

export default {
  name: "HangTag",
  mixins: [validationMixin],
  components: {
    vueDropzone: vue2Dropzone,
    Comment,
  },
  validations: {
    model: {
      termsAndCondition: {
        checked(val) {
          if (this.saveAndSubmit) {
            return val;
          } else {
            return true;
          }
        },
      },
    },
  },

  data() {
    return {
      baseUrl: "",
      unsupportedFiles: [],
      items: [
        "I will be supplying the fabric",
        "Use Seamless Source sourcing service",
      ],
      commentDialog: false,
      isSubmit: false,
      isContinue: false,
      loading: false,
      submitting: false,
      saveAndSubmit: false,
      moqMessage: " (MOQ-1000pcs)",

      packagingItem: [
        "Bio Degradable",
        "Polybag Packing",
        "Carton",
        "Box",
        "Other",
      ],

      hangTagItems: [
        {
          id: 1,
          dimensions: "7cm * 7cm",
          material: "250 gsm Art Board Without Lamination",
          image: null,
        },
        {
          id: 2,
          dimensions: "7cm * 6cm",
          material: "250 gsm Art Board Without Lamination",
          image: null,
        },
        {
          id: 3,
          dimensions: "5cm * 8cm",
          material: "250 gsm Art Board Without Lamination",
          image: null,
        },
        {
          id: 4,
          dimensions: "6cm * 6cm",
          material: "250 gsm Art Board Without Lamination",
          image: null,
        },
        {
          id: 5,
          dimensions: "8cm * 7cm",
          material: "250 gsm Art Board Without Lamination",
          image: null,
        },
        {
          id: 6,
          dimensions: null,
          material: null,
          image: null,
        },
      ],

      alert: false,

      isSubmitted: 0,

      dropzoneOptions: {
        url: "https://",
        autoProcessQueue: false,
        thumbnailWidth: 200, // px
        thumbnailHeight: 200,
        maxFilesize: 50,
        dictDefaultMessage:
          "<div style='filter: grayscale(1)'>📤</div> Drag and Drop (or) Choose File(s)",
        acceptedFiles: utils.acceptedFileTypes,
        addRemoveLinks: true,
      },

      model: {
        hangTagImages: [],
        filesHangTag: [],
        hangTagOption: null,
        hangTagDimensions: "",
        packaging: "",
        foldingMethod: "",
        otherPackaging: "",
        packagingImages: [],
        filespackagingImages: [],
        termsAndCondition: false,
        customerSubmit: false,
      },
      ignoreFormEdit: false,
      packagingImagesTotalFileSizeError: false,
      packagingImagesFileSizeError: false,
      hangTagImagesTotalFileSizeError: false,
      hangTagImagesFileSizeError: false,
      totalFileSize: 102000000,
      maximumFileSize: 52000000,
      fileSummationErrorMessage:
        "Selected files are exceeding the maximum file summation limit (100mb)",
      maxFileSizeErrorMessage:
        "One or more files are too large to upload (50mb)",
      showProgress: false,
      progress: 0,
    };
  },

  props: {
    isOwner: {
      type: Boolean,
    },
    isEditAccess: {
      type: Boolean,
    },
  },

  watch: {
    model: {
      handler: function() {
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    },
  },

  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.model.termsAndCondition.$dirty) return errors;
      !this.$v.model.termsAndCondition.checked &&
        this.saveAndSubmit &&
        errors.push("You must accept the terms and conditions.");
      return errors;
    },
  },

  methods: {
    continueToNext() {
      this.$emit("set-done");
    },
    clearOtherPackaging() {
      this.model.otherPackaging = "";
    },
    reset() {
      this.$emit("reset-status", false);
    },
    continueStep() {
      this.isContinue = true;
      this.submit();
    },
    deleteFile(uniqueId) {
      restAdapter.delete("/api/tech_pack/file_delete/" + uniqueId).then(() => {
        notification.success("File deleted successfully");

        for (let index = 0; index < this.model.filesHangTag.length; index++) {
          if (this.model.filesHangTag[index].file.upload_name == uniqueId) {
            this.model.filesHangTag.splice(index, 1);
          }
        }
      });
    },
    deleteFilePackaging(uniqueId) {
      restAdapter.delete("/api/tech_pack/file_delete/" + uniqueId).then(() => {
        notification.success("File deleted successfully");

        for (
          let index = 0;
          index < this.model.filespackagingImages.length;
          index++
        ) {
          if (
            this.model.filespackagingImages[index].file.upload_name == uniqueId
          ) {
            this.model.filespackagingImages.splice(index, 1);
          }
        }
      });
    },
    collectErrors(file, message, xhr) {
      this.unsupportedFiles.push({ file });
    },

    addhangTagImages: function(file) {
      if (file.size > this.maximumFileSize) {
        this.hangTagImagesFileSizeError = true;
      }

      this.model.hangTagImages.push({ file });

      let fileSize = 0;
      for (let index = 0; index < this.model.hangTagImages.length; index++) {
        fileSize = fileSize + this.model.hangTagImages[index].file.size;
      }
      this.hangTagImagesTotalFileSizeError =
        fileSize > this.totalFileSize ? true : false;
    },

    removehangTagImages(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.hangTagImages.length; index++) {
        if (this.model.hangTagImages[index].file.name === file.name) {
          this.model.hangTagImages.splice(index, 1);
        }
        fileSize =
          fileSize +
          (this.model.hangTagImages[index]
            ? this.model.hangTagImages[index].file.size
            : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.hangTagImagesTotalFileSizeError =
        fileSize > this.totalFileSize ? true : false;
      this.hangTagImagesFileSizeError =
        this.unsupportedFiles.length > 0 ? true : false;
    },
    addpackagingImages: function(file) {
      if (file.size > this.maximumFileSize) {
        this.packagingImagesFileSizeError = true;
      }

      this.model.packagingImages.push({ file });

      let fileSize = 0;
      for (let index = 0; index < this.model.packagingImages.length; index++) {
        fileSize = fileSize + this.model.packagingImages[index].file.size;
      }
      this.packagingImagesTotalFileSizeError =
        fileSize > this.totalFileSize ? true : false;
    },

    removepackagingImages(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.packagingImages.length; index++) {
        if (this.model.packagingImages[index].file.name === file.name) {
          this.model.packagingImages.splice(index, 1);
        }
        fileSize =
          fileSize +
          (this.model.packagingImages[index]
            ? this.model.packagingImages[index].file.size
            : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.packagingImagesTotalFileSizeError =
        fileSize > this.totalFileSize ? true : false;
      this.packagingImagesFileSizeError =
        this.unsupportedFiles.length > 0 ? true : false;
    },
    getHangTag() {
      return restAdapter.get(
        `/api/inquiries/${this.inquiryId}/tech_pack/hangtag_packaging`
      );
    },
    commentSubmit() {
      this.commentDialog = false;
      this.isSubmit = true;
      this.submit();
      this.continueNextPage();
    },
    continueNextPage() {
      if (this.isContinue) {
        this.isContinue = false;
        this.$emit("set-done");
      }
    },
    addComment() {
      if (!this.isOwner) {
        this.commentDialog = true;
      } else {
        this.commentSubmit();
      }
    },
    async submit() {
      this.saveAndSubmit = false;

      this.$v.model.$touch();

      if (this.$v.model.$invalid) {
        return;
      }
      if (!this.isSubmit && this.$store.state.routeLeaveAlert) {
        this.addComment();
        return;
      }
      this.isSubmit = false;

      if (
        this.packagingImagesTotalFileSizeError ||
        this.packagingImagesFileSizeError ||
        this.hangTagImagesTotalFileSizeError ||
        this.hangTagImagesFileSizeError
      ) {
        this.model.customerSubmit = false;
        return;
      }

      if (this.unsupportedFiles.length > 0) {
        this.model.customerSubmit = false;
        this.alert = true;
        return;
      }

      const formData = new FormData();

      for (let index = 0; index < this.model.hangTagImages.length; index++) {
        formData.append(
          "hangtag_files[]",
          this.model.hangTagImages[index].file
        );
      }
      for (let index = 0; index < this.model.packagingImages.length; index++) {
        formData.append(
          "packaging_files[]",
          this.model.packagingImages[index].file
        );
      }
      formData.append("hangtag_option", this.model.hangTagOption);
      formData.append("folding_method", this.model.foldingMethod);
      formData.append("other_hangtag_dimensions", this.model.hangTagDimensions);
      formData.append("packaging", this.model.packaging);
      formData.append("other_packaging", this.model.otherPackaging);
      formData.append(
        "terms_and_conditions",
        this.model.termsAndCondition ? 1 : 0
      );
      formData.append("submitting", this.model.customerSubmit);
      this.showProgress = true;
      document.getElementById("hangtag-progress-indicator").scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
      await restAdapter
        .post(
          `/api/inquiries/${this.inquiryId}/tech_pack/hangtag_packaging`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              this.progress = Math.floor(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          }
        )
        .then((response) => {
          this.showProgress = false;
          this.reset();
          notification.success("Hangtag Details Saved Successfully");
          if (this.model.customerSubmit) {
            this.isSubmitted = 1;
            notification.success("Tech Pack Submitted Successfully");
          }
          this.model.customerSubmit = false;
        })
        .catch((error) => {
          this.model.customerSubmit = false;
          this.showProgress = false;
          this.loading = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
      resetLeaveAlert();
      this.ignoreFormEdit = true;
      this.$v.model.$reset();
      return true;
    },
    async submitTechPack() {
      this.model.customerSubmit = true;
      this.saveAndSubmit = true;
      this.submitting = true;
      this.$v.model.$touch();
      if (this.$v.model.$invalid) {
        this.model.customerSubmit = false;
        this.submitting = false;
        return;
      }
      if (
        this.packagingImagesTotalFileSizeError ||
        this.packagingImagesFileSizeError ||
        this.hangTagImagesTotalFileSizeError ||
        this.hangTagImagesFileSizeError
      ) {
        this.model.customerSubmit = false;
        this.submitting = false;
        return;
      }
      await this.submit();
      this.submitting = false;
    },
  },

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;

    this.loading = true;

    this.getHangTag().then((response) => {
      const hangTag = response.data.hangtag_packaging;

      if (hangTag) {
        this.model.hangTagOption = parseInt(hangTag.hangtag_option);
        this.model.packaging = hangTag.packaging;
        this.model.hangTagDimensions = hangTag.other_hangtag_dimensions;
        this.model.foldingMethod = hangTag.folding_method;
        this.model.otherPackaging = hangTag.other_packaging;

        this.model.termsAndCondition =
          response.data.tech_pack.terms_and_conditions === 1 ? true : false;

        this.isSubmitted = response.data.tech_pack.is_submitted;

        for (
          let index = 0;
          index < response.data.hangtag_files.length;
          index++
        ) {
          this.model.filesHangTag.push({
            file: response.data.hangtag_files[index],
          });
        }

        for (
          let index = 0;
          index < response.data.packaging_files.length;
          index++
        ) {
          this.model.filespackagingImages.push({
            file: response.data.packaging_files[index],
          });
        }

        this.loading = false;
        this.ignoreFormEdit = true;
      }
    });

    this.loading = false;
  },
};
</script>

<style lang="css" scoped>
.tech-pack-hangtag {
  height: 500px;
}

.btn-align {
  display: flex;
  justify-content: flex-end;
}

.dropzone:hover {
  border: dashed #c6a8ec;
}

.dropzone {
  border-radius: 5px;
}

.contain {
  border: 1px solid #582963;
  height: 150px;
  overflow: hidden;
  resize: both;
  width: 220px;
  border-radius: 5px;
  cursor: pointer;
}

.dropzone >>> .dz-preview .dz-progress {
  opacity: 0 !important;
}

.dropzone >>> .dz-error-message {
  display: none !important;
}

.dropzone >>> .dz-preview .dz-details {
  background-color: rgb(198, 168, 236, 0.7) !important;
}

.dropzone >>> .dz-preview .dz-remove {
  color: #582963 !important;
}

.section {
  padding: 20px 50px 50px 50px;
  text-align: left;
}

.radio-value {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .btn-align {
    display: flex;
    justify-content: center;
  }

  .section {
    padding: 20px 10px 10px 10px;
  }

  .radio-value {
    display: grid;
  }

  .spinner {
    position: relative;
    z-index: 999;
  }
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.continue_button_color {
  background-color: #492a67 !important;
  border: #492a67 !important;
}

.md-progress-bar {
  height: 25px;
  margin: 5px;
}

.md-progress-bar.md-theme-default.md-determinate::v-deep .md-progress-bar-fill {
  background-color: #492a67 !important;
}

.md-progress-bar.md-theme-default.md-determinate {
  background-color: #c6a8ec !important;
}
</style>
