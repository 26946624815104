<template>
  <v-container fluid>
    <div class="tech-pack">
      <md-steppers class="margins stepper_number_color" :md-active-step.sync="active" md-linear @md-changed="scrollToTop">
        <md-step
          id="first"
          :md-error="firstStepError"
          :md-done.sync="first"
          md-label="Style Information"
        >
          <TechPack
            v-on:set-done="setDone('first', 'second')"
            v-on:error="
              setError('first');
              setErrorStep1();
            "
            :isOwner="isOwner()"
            :isEditAccess="isEditAccess()"
            v-if="renderTechPackComponent"
            @reset-status="forceTechPackRender"
          />
        </md-step>

        <md-step
          id="second"
          :md-error="secondStepError"
          :md-done.sync="second"
          md-label="Size and Quantities"
        >
          <Production
            v-on:set-done="setDone('second', 'third')"
            v-on:error="
              setError('second');
              setErrorStep2();
            "
            :isOwner="isOwner()"
            :isEditAccess="isEditAccess()"
            v-if="renderComponent"
            @reset-status="forceRerender"
          />
        </md-step>

        <md-step
          id="third"
          :md-error="thirdStepError"
          :md-done.sync="third"
          md-label="Fabric Details"
        >
          <Fabric
            v-on:set-done="setDone('third', 'fourth')"
            v-on:error="
              setError('third');
              setErrorStep3();
            "
            :isOwner="isOwner()"
            :isEditAccess="isEditAccess()"
            v-if="renderFabricComponent"
            @reset-status="forceFabricRender"
          />
        </md-step>
        <md-step
          id="fourth"
          :md-error="fourthStepError"
          :md-done.sync="fourth"
          md-label="Design and Trims"
        >
          <Style
            v-on:set-done="setDone('fourth', 'fifth')"
            v-on:error="
              setError('fourth');
              setErrorStep4();
            "
            :isOwner="isOwner()"
            :isEditAccess="isEditAccess()"
            v-if="renderStyleComponent"
            @reset-status="forceStyleRender"
          />
        </md-step>
        <md-step
          id="fifth"
          :md-done.sync="fifth"
          md-label="Hangtag and Packaging"
        >
          <HangTag
            v-on:set-done="setDone('fifth', 'sixth')"
            v-if="renderHangTagComponent"
            :isOwner="isOwner()"
            :isEditAccess="isEditAccess()"
            @reset-status="forceHangTagRender"
          />
        </md-step>
      </md-steppers>
    </div>
    <!-- </base-material-card> -->
  </v-container>
</template>

<script>
import * as conf from "../../conf.yml";
import restAdapter from "../../restAdapter";
import TechPack from "./techPackDevelopmentSteps/TechPack.vue";
import Production from "./techPackDevelopmentSteps/Production.vue";
import Fabric from "./techPackDevelopmentSteps/Fabric.vue";
import Style from "./techPackDevelopmentSteps/Style.vue";
import HangTag from "./techPackDevelopmentSteps/HangTag.vue";
import auth from "../../auth";
import { confirmBox, resetLeaveAlert } from "../../utils/confirmBox";

export default {
  name: "TechpackDevelopment",
  components: {
    TechPack: TechPack,
    Production: Production,
    Fabric: Fabric,
    Style: Style,
    HangTag: HangTag,
  },

  data() {
    return {
      baseUrl: "",
      inquiryId: null,
      active: "first",
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
      sixth: false,
      secondStepError: null,
      firstStepError: null,
      thirdStepError: null,
      fourthStepError: null,
      fifthStepError: null,
      renderComponent: true,
      renderFabricComponent: true,
      renderTechPackComponent: true,
      renderStyleComponent: true,
      renderHangTagComponent: true,
      createdBy: null,
      assignedAccountManagers: [],
    };
  },

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;
    if (this.$route.query && this.$route.query.tab){
      this.active = this.$route.query.tab;
    }

    this.getInquiry(this.inquiryId).then((response) => {
      const inquiry = response.data.inquiry;
      this.createdBy = inquiry.created_by;
      this.assignedAccountManagers = inquiry.account_managers;

      if (!this.isEditAccess()) {
        this.first = true;
        this.second = true;
        this.third = true;
        this.fourth = true;
        this.fifth = true;
        this.sixth = true;
      }
    });
  },

  methods: {
    scrollToTop(){
      window.scrollTo(0,0);
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    isOwner() {
      return this.createdBy === auth.user.id;
    },
    isEditAccess() {
      return this.createdBy === auth.user.id || (
        this.assignedAccountManagers &&
        this.assignedAccountManagers.find((accountManager) => accountManager.id === auth.user.id)
      )
    },
    forceRerender(emitStatus) {
      // remove the my-component component from the DOM
      this.renderComponent = false;
      resetLeaveAlert();
      this.$nextTick(() => {
        // add my-component component in DOM
        this.renderComponent = true;
      });
    },

    forceFabricRender(emitStatus) {
      this.renderFabricComponent = false;
      resetLeaveAlert();
      this.$nextTick(() => {
        // add my-component component in DOM
        this.renderFabricComponent = true;
      });
    },

    forceTechPackRender(emitStatus) {
      this.renderTechPackComponent = false;
      resetLeaveAlert();
      this.$nextTick(() => {
        // add my-component component in DOM
        this.renderTechPackComponent = true;
      });
    },

    forceStyleRender(emitStatus) {
      this.renderStyleComponent = false;
      resetLeaveAlert();
      this.$nextTick(() => {
        // add my-component component in DOM
        this.renderStyleComponent = true;
      });
    },

    forceHangTagRender(emitStatus) {
      this.renderHangTagComponent = false;
      resetLeaveAlert();
      this.$nextTick(() => {
        // add my-component component in DOM
        this.renderHangTagComponent = true;
      });
    },

    setDone(id, index) {
      resetLeaveAlert();
      this[id] = true;

      this.secondStepError = null;
      this.firstStepError = null;
      this.thirdStepError = null;
      this.fourthStepError = null;

      if (index) {
        this.active = index;
      }
    },
    setError(step) {
      if (step === "second") {
        this.secondStepError = "Fill required fields!";
      } else if (step === "first") {
        this.firstStepError = "Fill Style Name";
      } else if (step === "third") {
        this.thirdStepError = "Fill required fields";
      } else if (step === "fourth") {
        this.fourthStepError = "Fill required fields";
      }
    },
    setErrorStep1() {
      return true;
    },
    setErrorStep2() {
      return true;
    },
    setErrorStep3() {
      return true;
    },
    setErrorStep4() {
      return true;
    },
  },
  beforeRouteLeave(to, from, next) {
    confirmBox((this.isEditAccess() && this.$store.state.routeLeaveAlert), next);
  }
};
</script>

<style lang="css" scoped>
.contain {
  border: 1px solid gray;
  height: 200px;
  overflow: hidden;
  resize: both;
  width: 320px;
  border-radius: 5px;
  cursor: pointer;
}

.tech-pack {
  display: block !important;
  padding: 10px;
}

.md-steppers >>> .md-steppers-navigation {
  display: block !important;
}

.margins {
  margin-left: 100px;
  margin-right: 100px;
}

@media only screen and (max-width: 600px) {
  .tech-pack .md-steppers >>> .md-stepper-text {
    display: none;
  }

  .margins {
    margin-left: 0;
    margin-right: 0;
  }
}

.stepper_number_color::v-deep .md-stepper-header.md-active .md-stepper-number {
    background-color: #c6a8ec !important ;
}

.stepper_number_color::v-deep .md-stepper-header.md-done .md-stepper-number {
    background-color: #c6a8ec !important ;
}

</style>
